import React, { ReactNode } from 'react';
import styles from './Sidebar.module.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import ababaTechLogo from '../../assets/ababa_tech_logo.png';
import { routes } from '../../config/Router/routes';
import cx from 'classnames';
import { NavigationGroup } from '../Layout/Layout';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Roles } from '../../domain/User';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import MenuSubItems from './MenuSubItems/MenuSubItems';

type Props = {
  navigationGroups: NavigationGroup[];
  currentAccount?: ReactNode;
  selectedRole: Roles | null;
  selectedLocale: Locale;
};

const Sidebar = ({
  navigationGroups,
  currentAccount,
  selectedRole,
  selectedLocale,
}: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarContent}>
        <div className={styles.mainContent}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              <Link to={getLocaleUrl(routes.mainTasks.list, selectedLocale)}>
                <img src={logo} alt="LegalFit" />
              </Link>
            </div>
          </div>
          <hr className={styles.sidebarDivider} />
          {currentAccount}
          <div
            className={cx(styles.menuItemsContainer, {
              [styles.noCompanySelect]: !currentAccount,
            })}
          >
            {navigationGroups.map((sidebarItem) => (
              <MenuSubItems
                key={sidebarItem.label}
                sidebarItems={sidebarItem.items}
                label={sidebarItem.label}
              />
            ))}
          </div>
          <div className={styles.bottomLogoWrapper}>
            <hr className={styles.bottomLogoDivider} />
            <div className={styles.logoContainer}>
              <p className={styles.bottomLogoText}>
                {translate(intl, 'SIDEBAR.POWERED_BY')}
              </p>
              <div className={styles.bottomLogo}>
                <a
                  href="https://ababa.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ababaTechLogo} alt="ababa.tech logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomScrollContainer}>
          <div className={styles.bottomScroll} />
        </div>
        <div className={styles.rightScrollContainer}>
          <div className={styles.rightScroll} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
