import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createCompanyStart,
  createCompanySuccess,
  createCompanyFail,
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  fetchCompanyStart,
  fetchCompanySuccess,
  updateCompanyStart,
  fetchCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  fetchCompanyOptionsStart,
  fetchCompanyOptionsSuccess,
  fetchCompanyOptionsFail,
  toggleCompanyStatusStart,
  toggleCompanyStatusSuccess,
  toggleCompanyStatusFail,
} from './actions';
import { API_URLS } from '../constants';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';

import { translate } from '../../utility/messageTranslator/translate';

export type CompanyCreateRequest = {
  name: string;
  ownerId: string;
  locale: string;
  code: string;
};

export type CompanyUpdateRequest = {
  id: number;
  name: string;
  ownerId: string;
  locale: string;
  code: string;
};

export const fetchCompanies = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchCompaniesStart());
  return axios
    .get(API_URLS.company, { params })
    .then((response) => {
      dispatch(fetchCompaniesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompaniesFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const fetchCompanyOptions = () => (dispatch: Dispatch) => {
  dispatch(fetchCompanyOptionsStart());
  return axios
    .get(`${API_URLS.company}/options`)
    .then((response) => {
      dispatch(fetchCompanyOptionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompanyOptionsFail(err?.response?.data?.error));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const fetchCompany = (companyId: string) => (dispatch: Dispatch) => {
  dispatch(fetchCompanyStart());
  return axios
    .get(`${API_URLS.company}/${companyId}`)
    .then((response) => {
      dispatch(fetchCompanySuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompanyFail(err?.response?.data?.message));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const createCompany =
  (inputs: CompanyCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createCompanyStart());
    return axios
      .post(API_URLS.company, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createCompanySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.COMPANY_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createCompanyFail(err?.response?.data?.message));
      });
  };

export const updateCompany =
  (inputs: CompanyUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCompanyStart());
    return axios
      .patch(`${API_URLS.company}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCompanyFail(err?.response?.data?.message));
      });
  };

export const toggleStatus = (id: number, intl: IntlShape) => {
  return (dispatch: Dispatch) => {
    dispatch(toggleCompanyStatusStart());
    return axios
      .patch(`${API_URLS.company}/${id}/toggle-status`)
      .then(() => {
        dispatch(toggleCompanyStatusSuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_STATUS'), 'success');
      })
      .catch((err) => {
        dispatch(toggleCompanyStatusFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
};
