import axios from '../../config/Axios/axios-instance';
import {
  fetchLanguageFail,
  fetchLanguageStart,
  fetchLanguageSuccess,
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguagesFail,
} from './actions';
import { Dispatch } from 'redux';
import { API_URLS } from '../constants';

export const fetchLanguage = (locale: string) => (dispatch: Dispatch) => {
  dispatch(fetchLanguageStart());
  return axios
    .get(`${API_URLS.language}/${locale}`)
    .then((response) => {
      dispatch(fetchLanguageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguageFail(err?.response?.data?.error));
    });
};

export const fetchLanguages = () => (dispatch: Dispatch) => {
  dispatch(fetchLanguagesStart());
  return axios
    .get(API_URLS.language)
    .then((response) => {
      dispatch(fetchLanguagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguagesFail(err?.response?.data?.error));
    });
};
