import * as actionTypes from './actionTypes';
import { SigneeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Signee, SigneesList } from '../../domain/Signee';

export type SigneeStateType = {
  signeesList: SigneesList | null;
  signeesLoading: boolean;
  signeesError: HttpError;
  signeesListUpdateNeeded: boolean;
  signeeCreateLoading: boolean;
  signeeCreateError: HttpError;
  signeeCreateSuccess: boolean;
  createdSignee: Signee | null;
  signeeUpdateLoading: boolean;
  signeeUpdateError: HttpError;
  signeeUpdateSuccess: boolean;
  signee: Signee | null;
  signeeLoading: boolean;
  signeeError: HttpError;
  signeeDeleteLoading: boolean;
  signeeDeleteError: HttpError;
  signeeDeleteSuccess: boolean;
  toggleSigneeStatusLoading: boolean;
  toggleSigneeStatusSuccess: boolean;
  toggleSigneeStatusError: HttpError;
};

export type SigneeActionType = SigneeStateType & {
  type: SigneeActionTypes;
};

export const initialState: SigneeStateType = {
  signeesList: null,
  signeesLoading: true,
  signeesError: null,
  signeesListUpdateNeeded: false,
  signeeCreateLoading: false,
  signeeCreateError: null,
  signeeCreateSuccess: false,
  createdSignee: null,
  signeeUpdateLoading: false,
  signeeUpdateError: null,
  signeeUpdateSuccess: false,
  signee: null,
  signeeLoading: false,
  signeeError: null,
  signeeDeleteLoading: false,
  signeeDeleteError: null,
  signeeDeleteSuccess: false,
  toggleSigneeStatusLoading: false,
  toggleSigneeStatusSuccess: false,
  toggleSigneeStatusError: null,
};

const fetchSigneesStart = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  signeesLoading: true,
});

const fetchSigneesSuccess = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signeesList: action.signeesList,
  signeesLoading: false,
  signeesError: null,
  signeesListUpdateNeeded: false,
});

const fetchSigneesFail = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signeesError: action.signeesError,
  signeesLoading: false,
});

const fetchSigneeStart = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  signeeLoading: true,
  signeeUpdateSuccess: false,
  signeeCreateSuccess: false,
  signeeCreateError: null,
  signeeUpdateError: null,
});

const fetchSigneeSuccess = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signee: action.signee,
  signeeLoading: false,
  signeeError: null,
});

const fetchSigneeFail = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signeeError: action.signeeError,
  signeeLoading: false,
});

const createSigneeStart = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  signeeCreateLoading: true,
});

const createSigneeSuccess = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signeeCreateLoading: false,
  signeeCreateError: null,
  signeeCreateSuccess: true,
  createdSignee: action.createdSignee,
  signeesList: state.signeesList
    ? {
        total: state.signeesList.total + 1,
        result: [...state.signeesList.result, action.createdSignee] as Signee[],
      }
    : null,
});

const createSigneeFail = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signeeCreateLoading: false,
  signeeCreateError: action.signeeCreateError,
});

const updateSigneeStart = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  signeeUpdateLoading: true,
});

const updateSigneeSuccess = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  signeeUpdateLoading: false,
  signeeUpdateError: null,
  signeeUpdateSuccess: true,
});

const updateSigneeFail = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signeeUpdateLoading: false,
  signeeUpdateError: action.signeeUpdateError,
});

const deleteSigneeStart = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  signeeDeleteLoading: true,
});

const deleteSigneeSuccess = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  signeeDeleteLoading: false,
  signeeDeleteError: null,
  signeeDeleteSuccess: true,
  signeesListUpdateNeeded: true,
});

const deleteSigneeFail = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  signeeDeleteLoading: false,
  signeeDeleteError: action.signeeDeleteError,
});

const toggleSigneeStatusStart = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  toggleSigneeStatusLoading: true,
});

const toggleSigneeStatusSuccess = (
  state: SigneeStateType,
): SigneeStateType => ({
  ...state,
  toggleSigneeStatusLoading: false,
  toggleSigneeStatusError: null,
  toggleSigneeStatusSuccess: true,
  signeesListUpdateNeeded: true,
});

const toggleSigneeStatusFail = (
  state: SigneeStateType,
  action: SigneeActionType,
): SigneeStateType => ({
  ...state,
  toggleSigneeStatusLoading: false,
  toggleSigneeStatusError: action.toggleSigneeStatusError,
});

const resetCreatedSignee = (state: SigneeStateType): SigneeStateType => ({
  ...state,
  createdSignee: null,
});

const resetSigneeStore = (): SigneeStateType => ({
  signeesList: null,
  signeesLoading: true,
  signeesError: null,
  signeesListUpdateNeeded: false,
  signeeCreateLoading: false,
  signeeCreateError: null,
  signeeCreateSuccess: false,
  createdSignee: null,
  signeeUpdateLoading: false,
  signeeUpdateError: null,
  signeeUpdateSuccess: false,
  signee: null,
  signeeLoading: false,
  signeeError: null,
  signeeDeleteLoading: false,
  signeeDeleteError: null,
  signeeDeleteSuccess: false,
  toggleSigneeStatusSuccess: false,
  toggleSigneeStatusError: null,
  toggleSigneeStatusLoading: false,
});

const reducer = (state = initialState, action: SigneeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SIGNEES_START:
      return fetchSigneesStart(state);
    case actionTypes.FETCH_SIGNEES_SUCCESS:
      return fetchSigneesSuccess(state, action);
    case actionTypes.FETCH_SIGNEES_FAIL:
      return fetchSigneesFail(state, action);
    case actionTypes.FETCH_SIGNEE_START:
      return fetchSigneeStart(state);
    case actionTypes.FETCH_SIGNEE_SUCCESS:
      return fetchSigneeSuccess(state, action);
    case actionTypes.FETCH_SIGNEE_FAIL:
      return fetchSigneeFail(state, action);
    case actionTypes.CREATE_SIGNEE_START:
      return createSigneeStart(state);
    case actionTypes.CREATE_SIGNEE_SUCCESS:
      return createSigneeSuccess(state, action);
    case actionTypes.CREATE_SIGNEE_FAIL:
      return createSigneeFail(state, action);
    case actionTypes.UPDATE_SIGNEE_START:
      return updateSigneeStart(state);
    case actionTypes.UPDATE_SIGNEE_SUCCESS:
      return updateSigneeSuccess(state);
    case actionTypes.UPDATE_SIGNEE_FAIL:
      return updateSigneeFail(state, action);
    case actionTypes.DELETE_SIGNEE_START:
      return deleteSigneeStart(state);
    case actionTypes.DELETE_SIGNEE_SUCCESS:
      return deleteSigneeSuccess(state);
    case actionTypes.DELETE_SIGNEE_FAIL:
      return deleteSigneeFail(state, action);
    case actionTypes.RESET_SIGNEE_STORE:
      return resetSigneeStore();
    case actionTypes.TOGGLE_SIGNEE_STATUS_START:
      return toggleSigneeStatusStart(state);
    case actionTypes.TOGGLE_SIGNEE_STATUS_SUCCESS:
      return toggleSigneeStatusSuccess(state);
    case actionTypes.TOGGLE_SIGNEE_STATUS_FAIL:
      return toggleSigneeStatusFail(state, action);
    case actionTypes.RESET_CREATED_SIGNEE:
      return resetCreatedSignee(state);
    default:
      return state;
  }
};

export default reducer;
