import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Roles, User } from '../../domain/User';
import { Locale } from '../../domain/Translation';

export const loginGoogleStart = () => ({
  type: actionTypes.LOGIN_GOOGLE_START,
});

export const loginGoogleSuccess = () => ({
  type: actionTypes.LOGIN_GOOGLE_SUCCESS,
});

export const loginGoogleFail = (loginGoogleError: string) => ({
  type: actionTypes.LOGIN_GOOGLE_FAIL,
  loginGoogleError,
});

export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginSuccess = () => ({
  type: actionTypes.LOGIN_SUCCESS,
});

export const loginFail = (loginError: HttpError) => ({
  type: actionTypes.LOGIN_FAIL,
  loginError,
});

export const remindStart = () => ({
  type: actionTypes.REMIND_START,
});

export const remindSuccess = () => ({
  type: actionTypes.REMIND_SUCCESS,
});

export const remindFail = (remindError: HttpError) => ({
  type: actionTypes.REMIND_FAIL,
  remindError,
});

export const resetPasswordStart = () => ({
  type: actionTypes.RESET_PASSWORD_START,
});

export const resetPasswordSuccess = () => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFail = (resetPasswordError: HttpError) => ({
  type: actionTypes.RESET_PASSWORD_FAIL,
  resetPasswordError,
});

export const validateCodeStart = () => ({
  type: actionTypes.VALIDATE_CODE_START,
});

export const validateCodeSuccess = (validatedUser: User) => ({
  type: actionTypes.VALIDATE_CODE_SUCCESS,
  validatedUser,
});

export const validateCodeFail = (validateCodeError: HttpError) => ({
  type: actionTypes.VALIDATE_CODE_FAIL,
  validateCodeError,
});

export const validateRegistrationCodeStart = () => ({
  type: actionTypes.VALIDATE_REGISTRATION_CODE_START,
});

export const validateRegistrationCodeSuccess = (validatedUser: User) => ({
  type: actionTypes.VALIDATE_REGISTRATION_CODE_SUCCESS,
  validatedUser,
});

export const validateRegistrationCodeFail = (
  validateRegisterCodeError: HttpError,
) => ({
  type: actionTypes.VALIDATE_REGISTRATION_CODE_FAIL,
  validateRegisterCodeError,
});

export const registerStart = () => ({
  type: actionTypes.REGISTER_START,
});

export const registerSuccess = () => ({
  type: actionTypes.REGISTER_SUCCESS,
});

export const registerFail = (registerError: HttpError) => ({
  type: actionTypes.REGISTER_FAIL,
  registerError,
});

export const selectLocale = (selectedLocale: Locale) => ({
  type: actionTypes.SELECT_LOCALE,
  selectedLocale,
});

export const setRole = (role: Roles) => ({
  type: actionTypes.SET_ROLE,
  role,
});

export const logout = () => {
  localStorage.removeItem('token');
  return {
    type: actionTypes.LOGOUT,
  };
};
