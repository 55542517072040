export const FETCH_TASK_TEMPLATES_START = 'FETCH_TASK_TEMPLATES_START';
export const FETCH_TASK_TEMPLATES_SUCCESS = 'FETCH_TASK_TEMPLATES_SUCCESS';
export const FETCH_TASK_TEMPLATES_FAIL = 'FETCH_TASK_TEMPLATES_FAIL';

export const CREATE_TASK_TEMPLATE_START = 'CREATE_TASK_TEMPLATE_START';
export const CREATE_TASK_TEMPLATE_SUCCESS = 'CREATE_TASK_TEMPLATE_SUCCESS';
export const CREATE_TASK_TEMPLATE_FAIL = 'CREATE_TASK_TEMPLATE_FAIL';

export const DELETE_TASK_TEMPLATE_START = 'DELETE_TASK_TEMPLATE_START';
export const DELETE_TASK_TEMPLATE_SUCCESS = 'DELETE_TASK_TEMPLATE_SUCCESS';
export const DELETE_TASK_TEMPLATE_FAIL = 'DELETE_TASK_TEMPLATE_FAIL';

export const UPDATE_TASK_TEMPLATE_START = 'UPDATE_TASK_TEMPLATE_START';
export const UPDATE_TASK_TEMPLATE_SUCCESS = 'UPDATE_TASK_TEMPLATE_SUCCESS';
export const UPDATE_TASK_TEMPLATE_FAIL = 'UPDATE_TASK_TEMPLATE_FAIL';

export const FETCH_TASK_TEMPLATE_START = 'FETCH_TASK_TEMPLATE_START';
export const FETCH_TASK_TEMPLATE_SUCCESS = 'FETCH_TASK_TEMPLATE_SUCCESS';
export const FETCH_TASK_TEMPLATE_FAIL = 'FETCH_TASK_TEMPLATE_FAIL';

export const FETCH_ALL_TASK_TEMPLATES_START = 'FETCH_ALL_TASK_TEMPLATES_START';
export const FETCH_ALL_TASK_TEMPLATES_SUCCESS =
  'FETCH_ALL_TASK_TEMPLATES_SUCCESS';
export const FETCH_ALL_TASK_TEMPLATES_FAIL = 'FETCH_ALL_TASK_TEMPLATES_FAIL';

export const RESET_TASK_TEMPLATE_STORE = 'RESET_TASK_TEMPLATE_STORE';

export type TaskTemplateActionTypes =
  | typeof FETCH_TASK_TEMPLATES_START
  | typeof FETCH_TASK_TEMPLATES_SUCCESS
  | typeof FETCH_TASK_TEMPLATES_FAIL
  | typeof CREATE_TASK_TEMPLATE_START
  | typeof CREATE_TASK_TEMPLATE_SUCCESS
  | typeof CREATE_TASK_TEMPLATE_FAIL
  | typeof DELETE_TASK_TEMPLATE_START
  | typeof DELETE_TASK_TEMPLATE_SUCCESS
  | typeof DELETE_TASK_TEMPLATE_FAIL
  | typeof UPDATE_TASK_TEMPLATE_START
  | typeof UPDATE_TASK_TEMPLATE_SUCCESS
  | typeof UPDATE_TASK_TEMPLATE_FAIL
  | typeof FETCH_TASK_TEMPLATE_START
  | typeof FETCH_TASK_TEMPLATE_SUCCESS
  | typeof FETCH_TASK_TEMPLATE_FAIL
  | typeof FETCH_ALL_TASK_TEMPLATES_START
  | typeof FETCH_ALL_TASK_TEMPLATES_SUCCESS
  | typeof FETCH_ALL_TASK_TEMPLATES_FAIL
  | typeof RESET_TASK_TEMPLATE_STORE;
