import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://93eae38b008542dc94d839239b3cbac5@o1101437.ingest.sentry.io/4504656018472960',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    debug: true,
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
