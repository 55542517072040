import * as actionTypes from './actionTypes';
import { TaskActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { ExportData, Task } from '../../domain/Task';
import { TaskDocument } from '../../domain/TaskDocument';

export type TaskStateType = {
  myTasksList: ListResults<Task> | null;
  myTasksLoading: boolean;
  myTasksError: HttpError;
  myTasksListUpdateNeeded: boolean;
  myTask: Task | null;
  myTaskLoading: boolean;
  myTaskError: HttpError;
  acknowledgeTaskDocumentLoading: boolean;
  acknowledgeTaskDocumentSuccess: boolean;
  acknowledgeTaskDocumentError: HttpError;
  acknowledgeTaskLoading: boolean;
  acknowledgeTaskSuccess: boolean;
  acknowledgeTaskError: HttpError;
  resendCodeLoading: boolean;
  resendCodeSuccess: boolean;
  resendCodeError: HttpError;
  signTaskLoading: boolean;
  signTaskSuccess: boolean;
  signTaskError: HttpError;
  exportDataLoading: boolean;
  exportDataError: HttpError;
  exportData: ExportData | null;
};

export type TaskActionType = TaskStateType & {
  type: TaskActionTypes;
  taskDocument: TaskDocument;
};

export const initialState: TaskStateType = {
  myTasksList: null,
  myTasksLoading: true,
  myTasksError: null,
  myTasksListUpdateNeeded: false,
  myTask: null,
  myTaskLoading: false,
  myTaskError: null,
  acknowledgeTaskDocumentError: null,
  acknowledgeTaskDocumentSuccess: false,
  acknowledgeTaskDocumentLoading: false,
  acknowledgeTaskError: null,
  acknowledgeTaskSuccess: false,
  acknowledgeTaskLoading: false,
  resendCodeError: null,
  resendCodeSuccess: false,
  resendCodeLoading: false,
  signTaskError: null,
  signTaskSuccess: false,
  signTaskLoading: false,
  exportData: null,
  exportDataError: null,
  exportDataLoading: false,
};

const fetchMyTasksStart = (state: TaskStateType): TaskStateType => ({
  ...state,
  myTasksLoading: true,
});

const fetchMyTasksSuccess = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  myTasksList: action.myTasksList,
  myTasksLoading: false,
  myTasksError: null,
  myTasksListUpdateNeeded: false,
});

const fetchMyTasksFail = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  myTasksError: action.myTasksError,
  myTasksLoading: false,
});

const fetchMyTaskStart = (state: TaskStateType): TaskStateType => ({
  ...state,
  myTaskLoading: true,
});

const fetchMyTaskSuccess = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  myTask: action.myTask,
  myTaskLoading: false,
  myTaskError: null,
  myTasksListUpdateNeeded: false,
});

const fetchMyTaskFail = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  myTaskError: action.myTaskError,
  myTaskLoading: false,
});

const acknowledgeTaskDocumentStart = (state: TaskStateType): TaskStateType => ({
  ...state,
  acknowledgeTaskDocumentLoading: true,
  acknowledgeTaskDocumentSuccess: false,
});

const acknowledgeTaskDocumentSuccess = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  acknowledgeTaskDocumentLoading: false,
  acknowledgeTaskDocumentError: null,
  acknowledgeTaskDocumentSuccess: true,
  myTask: state.myTask
    ? {
        ...state.myTask,
        taskDocuments: state.myTask.taskDocuments.map(
          (taskDocument: TaskDocument) => {
            if (
              taskDocument.taskId === action.taskDocument.taskId &&
              taskDocument.documentVersionId ===
                action.taskDocument.documentVersionId
            ) {
              taskDocument.status = action.taskDocument.status;
              taskDocument.completedDate = action.taskDocument.completedDate;
            }

            return taskDocument;
          },
        ),
      }
    : null,
});

const acknowledgeTaskDocumentFail = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  acknowledgeTaskDocumentError: action.acknowledgeTaskDocumentError,
  acknowledgeTaskDocumentLoading: false,
  acknowledgeTaskDocumentSuccess: false,
});

const acknowledgeTaskStart = (state: TaskStateType): TaskStateType => ({
  ...state,
  acknowledgeTaskLoading: true,
  acknowledgeTaskSuccess: false,
});

const acknowledgeTaskSuccess = (state: TaskStateType): TaskStateType => ({
  ...state,
  acknowledgeTaskLoading: false,
  acknowledgeTaskError: null,
  acknowledgeTaskSuccess: true,
});

const acknowledgeTaskFail = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  acknowledgeTaskError: action.acknowledgeTaskError,
  acknowledgeTaskLoading: false,
  acknowledgeTaskSuccess: false,
});

const resendCodeStart = (state: TaskStateType): TaskStateType => ({
  ...state,
  resendCodeLoading: true,
  resendCodeSuccess: false,
});

const resendCodeSuccess = (state: TaskStateType): TaskStateType => ({
  ...state,
  resendCodeLoading: false,
  resendCodeError: null,
  resendCodeSuccess: true,
});

const resendCodeFail = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  resendCodeError: action.resendCodeError,
  resendCodeLoading: false,
  resendCodeSuccess: false,
});

const signTaskStart = (state: TaskStateType): TaskStateType => ({
  ...state,
  signTaskLoading: true,
  signTaskSuccess: false,
});

const signTaskSuccess = (state: TaskStateType): TaskStateType => ({
  ...state,
  signTaskLoading: false,
  signTaskError: null,
  signTaskSuccess: true,
});

const signTaskFail = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  signTaskError: action.signTaskError,
  signTaskLoading: false,
  signTaskSuccess: false,
});

const exportDataStart = (state: TaskStateType): TaskStateType => ({
  ...state,
  exportDataLoading: true,
  exportData: null,
});

const exportDataSuccess = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  exportDataLoading: false,
  exportDataError: null,
  exportData: action.exportData,
});

const exportDataFail = (
  state: TaskStateType,
  action: TaskActionType,
): TaskStateType => ({
  ...state,
  exportDataError: action.exportDataError,
  exportDataLoading: false,
});

const resetExportStore = (state: TaskStateType): TaskStateType => ({
  ...state,
  exportDataError: null,
  exportDataLoading: false,
  exportData: null,
});

const resetResendCodeStore = (state: TaskStateType): TaskStateType => ({
  ...state,
  resendCodeSuccess: false,
  resendCodeLoading: false,
  resendCodeError: null,
});

const resetSignTaskStore = (state: TaskStateType): TaskStateType => ({
  ...state,
  signTaskSuccess: false,
  signTaskLoading: false,
  signTaskError: null,
  acknowledgeTaskError: null,
  acknowledgeTaskSuccess: false,
  acknowledgeTaskLoading: false,
});

const resetMainTaskStore = (): TaskStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TaskActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_TASKS_START:
      return fetchMyTasksStart(state);
    case actionTypes.FETCH_MY_TASKS_SUCCESS:
      return fetchMyTasksSuccess(state, action);
    case actionTypes.FETCH_MY_TASKS_FAIL:
      return fetchMyTasksFail(state, action);
    case actionTypes.FETCH_MY_TASK_START:
      return fetchMyTaskStart(state);
    case actionTypes.FETCH_MY_TASK_SUCCESS:
      return fetchMyTaskSuccess(state, action);
    case actionTypes.FETCH_MY_TASK_FAIL:
      return fetchMyTaskFail(state, action);
    case actionTypes.ACKNOWLEDGE_TASK_DOCUMENT_START:
      return acknowledgeTaskDocumentStart(state);
    case actionTypes.ACKNOWLEDGE_TASK_DOCUMENT_SUCCESS:
      return acknowledgeTaskDocumentSuccess(state, action);
    case actionTypes.ACKNOWLEDGE_TASK_DOCUMENT_FAIL:
      return acknowledgeTaskDocumentFail(state, action);
    case actionTypes.ACKNOWLEDGE_TASK_START:
      return acknowledgeTaskStart(state);
    case actionTypes.ACKNOWLEDGE_TASK_SUCCESS:
      return acknowledgeTaskSuccess(state);
    case actionTypes.ACKNOWLEDGE_TASK_FAIL:
      return acknowledgeTaskFail(state, action);
    case actionTypes.RESEND_CODE_START:
      return resendCodeStart(state);
    case actionTypes.RESEND_CODE_SUCCESS:
      return resendCodeSuccess(state);
    case actionTypes.RESEND_CODE_FAIL:
      return resendCodeFail(state, action);
    case actionTypes.SIGN_TASK_START:
      return signTaskStart(state);
    case actionTypes.SIGN_TASK_SUCCESS:
      return signTaskSuccess(state);
    case actionTypes.SIGN_TASK_FAIL:
      return signTaskFail(state, action);
    case actionTypes.EXPORT_DATA_START:
      return exportDataStart(state);
    case actionTypes.EXPORT_DATA_SUCCESS:
      return exportDataSuccess(state, action);
    case actionTypes.EXPORT_DATA_FAIL:
      return exportDataFail(state, action);
    case actionTypes.RESET_EXPORT_STORE:
      return resetExportStore(state);
    case actionTypes.RESET_RESEND_CODE_STORE:
      return resetResendCodeStore(state);
    case actionTypes.RESET_SIGN_TASK_STORE:
      return resetSignTaskStore(state);
    case actionTypes.RESET_TASK_STORE:
      return resetMainTaskStore();
    default:
      return state;
  }
};

export default reducer;
