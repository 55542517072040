import * as actionTypes from './actionTypes';
import { MainTaskActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { MainTask, MainTaskDetails } from '../../domain/MainTask';
import { Signee } from '../../domain/Signee';
import { DocumentVersion } from '../../domain/DocumentVersion';

export type MainTaskStateType = {
  mainTasksList: ListResults<MainTask> | null;
  mainTasksLoading: boolean;
  mainTasksError: HttpError;
  mainTasksListUpdateNeeded: boolean;
  mainTaskCreateLoading: boolean;
  mainTaskCreateError: HttpError;
  mainTaskCreateSuccess: boolean;
  createdMainTask: MainTask | null;
  mainTaskDetails: MainTaskDetails | null;
  mainTaskDetailsLoading: boolean;
  mainTaskDetailsError: HttpError;
  mainTaskDeleteLoading: boolean;
  mainTaskDeleteError: HttpError;
  mainTaskDeleteSuccess: boolean;
  mainTaskSignees: ListResults<Signee> | null;
  mainTaskSigneesLoading: boolean;
  mainTaskSigneesError: HttpError;
  mainTaskDocuments: ListResults<DocumentVersion> | null;
  mainTaskDocumentsLoading: boolean;
  mainTaskDocumentsError: HttpError;
  mainTaskResendCodeLoading: boolean;
  mainTaskResendCodeSuccess: boolean;
  mainTaskResendCodeError: HttpError;
};

export type MainTaskActionType = MainTaskStateType & {
  type: MainTaskActionTypes;
};

export const initialState: MainTaskStateType = {
  mainTasksList: null,
  mainTasksLoading: true,
  mainTasksError: null,
  mainTasksListUpdateNeeded: false,
  mainTaskCreateLoading: false,
  mainTaskCreateError: null,
  mainTaskCreateSuccess: false,
  createdMainTask: null,
  mainTaskDetails: null,
  mainTaskDetailsLoading: false,
  mainTaskDetailsError: null,
  mainTaskDeleteLoading: false,
  mainTaskDeleteError: null,
  mainTaskDeleteSuccess: false,
  mainTaskSignees: null,
  mainTaskSigneesError: null,
  mainTaskSigneesLoading: false,
  mainTaskDocumentsError: null,
  mainTaskDocumentsLoading: false,
  mainTaskDocuments: null,
  mainTaskResendCodeError: null,
  mainTaskResendCodeSuccess: false,
  mainTaskResendCodeLoading: false,
};

const fetchMainTasksStart = (state: MainTaskStateType): MainTaskStateType => ({
  ...state,
  mainTasksLoading: true,
});

const fetchMainTasksSuccess = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTasksList: action.mainTasksList,
  mainTasksLoading: false,
  mainTasksError: null,
  mainTasksListUpdateNeeded: false,
  mainTaskCreateSuccess: false,
  mainTaskDeleteSuccess: false,
});

const fetchMainTasksFail = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTasksError: action.mainTasksError,
  mainTasksLoading: false,
});

const fetchMainTaskStart = (state: MainTaskStateType): MainTaskStateType => ({
  ...state,
  mainTaskDetailsLoading: true,
  mainTaskCreateSuccess: false,
  mainTaskCreateError: null,
});

const fetchMainTaskSuccess = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskDetails: action.mainTaskDetails,
  mainTaskDetailsLoading: false,
  mainTaskDetailsError: null,
});

const fetchMainTaskFail = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskDetailsError: action.mainTaskDetailsError,
  mainTaskDetailsLoading: false,
});

const createMainTaskStart = (state: MainTaskStateType): MainTaskStateType => ({
  ...state,
  mainTaskCreateLoading: true,
});

const fetchMainTaskSigneeStart = (
  state: MainTaskStateType,
): MainTaskStateType => ({
  ...state,
  mainTaskSigneesLoading: true,
});

const fetchMainTaskSigneesSuccess = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskSignees: action.mainTaskSignees,
  mainTaskSigneesLoading: false,
  mainTaskSigneesError: null,
});

const fetchMainTaskSigneesFail = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskSigneesError: action.mainTaskSigneesError,
  mainTaskSigneesLoading: false,
});

const fetchMainTaskDocumentsStart = (
  state: MainTaskStateType,
): MainTaskStateType => ({
  ...state,
  mainTaskDocumentsLoading: true,
});

const fetchMainTaskDocumentsSuccess = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskDocuments: action.mainTaskDocuments,
  mainTaskDocumentsLoading: false,
  mainTaskDocumentsError: null,
});

const fetchMainTaskDocumentsFail = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskDocumentsError: action.mainTaskDocumentsError,
  mainTaskDocumentsLoading: false,
});

const createMainTaskSuccess = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskCreateLoading: false,
  mainTaskCreateError: null,
  mainTaskCreateSuccess: true,
  createdMainTask: action.createdMainTask,
});

const createMainTaskFail = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskCreateLoading: false,
  mainTaskCreateError: action.mainTaskCreateError,
});

const deleteMainTaskStart = (state: MainTaskStateType): MainTaskStateType => ({
  ...state,
  mainTaskDeleteLoading: true,
});

const deleteMainTaskSuccess = (
  state: MainTaskStateType,
): MainTaskStateType => ({
  ...state,
  mainTaskDeleteLoading: false,
  mainTaskDeleteError: null,
  mainTaskDeleteSuccess: true,
  mainTasksListUpdateNeeded: true,
});

const deleteMainTaskFail = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskDeleteLoading: false,
  mainTaskDeleteError: action.mainTaskDeleteError,
});

const mainTaskResendCodeStart = (
  state: MainTaskStateType,
): MainTaskStateType => ({
  ...state,
  mainTaskResendCodeLoading: true,
  mainTaskResendCodeSuccess: false,
});

const mainTaskResendCodeSuccess = (
  state: MainTaskStateType,
): MainTaskStateType => ({
  ...state,
  mainTaskResendCodeLoading: false,
  mainTaskResendCodeError: null,
  mainTaskResendCodeSuccess: true,
});

const mainTaskResendCodeFail = (
  state: MainTaskStateType,
  action: MainTaskActionType,
): MainTaskStateType => ({
  ...state,
  mainTaskResendCodeError: action.mainTaskResendCodeError,
  mainTaskResendCodeLoading: false,
  mainTaskResendCodeSuccess: false,
});

const resetMainTaskResendCodeStore = (
  state: MainTaskStateType,
): MainTaskStateType => ({
  ...state,
  mainTaskResendCodeSuccess: false,
  mainTaskResendCodeLoading: false,
  mainTaskResendCodeError: null,
});

const resetMainTaskStore = (): MainTaskStateType => ({
  mainTasksList: null,
  mainTasksLoading: true,
  mainTasksError: null,
  mainTasksListUpdateNeeded: false,
  mainTaskCreateLoading: false,
  mainTaskCreateError: null,
  mainTaskCreateSuccess: false,
  createdMainTask: null,
  mainTaskDetails: null,
  mainTaskDetailsLoading: false,
  mainTaskDetailsError: null,
  mainTaskDeleteLoading: false,
  mainTaskDeleteError: null,
  mainTaskDeleteSuccess: false,
  mainTaskSigneesLoading: false,
  mainTaskSigneesError: null,
  mainTaskSignees: null,
  mainTaskDocumentsError: null,
  mainTaskDocumentsLoading: false,
  mainTaskDocuments: null,
  mainTaskResendCodeSuccess: false,
  mainTaskResendCodeLoading: false,
  mainTaskResendCodeError: null,
});

const reducer = (state = initialState, action: MainTaskActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_MAIN_TASKS_START:
      return fetchMainTasksStart(state);
    case actionTypes.FETCH_MAIN_TASKS_SUCCESS:
      return fetchMainTasksSuccess(state, action);
    case actionTypes.FETCH_MAIN_TASKS_FAIL:
      return fetchMainTasksFail(state, action);
    case actionTypes.FETCH_MAIN_TASK_START:
      return fetchMainTaskStart(state);
    case actionTypes.FETCH_MAIN_TASK_SUCCESS:
      return fetchMainTaskSuccess(state, action);
    case actionTypes.FETCH_MAIN_TASK_FAIL:
      return fetchMainTaskFail(state, action);
    case actionTypes.CREATE_MAIN_TASK_START:
      return createMainTaskStart(state);
    case actionTypes.CREATE_MAIN_TASK_SUCCESS:
      return createMainTaskSuccess(state, action);
    case actionTypes.CREATE_MAIN_TASK_FAIL:
      return createMainTaskFail(state, action);
    case actionTypes.DELETE_MAIN_TASK_START:
      return deleteMainTaskStart(state);
    case actionTypes.DELETE_MAIN_TASK_SUCCESS:
      return deleteMainTaskSuccess(state);
    case actionTypes.DELETE_MAIN_TASK_FAIL:
      return deleteMainTaskFail(state, action);
    case actionTypes.FETCH_MAIN_TASK_SIGNEES_START:
      return fetchMainTaskSigneeStart(state);
    case actionTypes.FETCH_MAIN_TASK_SIGNEES_SUCCESS:
      return fetchMainTaskSigneesSuccess(state, action);
    case actionTypes.FETCH_MAIN_TASK_SIGNEES_FAIL:
      return fetchMainTaskSigneesFail(state, action);
    case actionTypes.FETCH_MAIN_TASK_DOCUMENTS_START:
      return fetchMainTaskDocumentsStart(state);
    case actionTypes.FETCH_MAIN_TASK_DOCUMENTS_SUCCESS:
      return fetchMainTaskDocumentsSuccess(state, action);
    case actionTypes.FETCH_MAIN_TASK_DOCUMENTS_FAIL:
      return fetchMainTaskDocumentsFail(state, action);
    case actionTypes.MAIN_TASK_RESEND_CODE_START:
      return mainTaskResendCodeStart(state);
    case actionTypes.MAIN_TASK_RESEND_CODE_SUCCESS:
      return mainTaskResendCodeSuccess(state);
    case actionTypes.MAIN_TASK_RESEND_CODE_FAIL:
      return mainTaskResendCodeFail(state, action);
    case actionTypes.RESET_MAIN_TASK_RESEND_CODE_STORE:
      return resetMainTaskResendCodeStore(state);
    case actionTypes.RESET_MAIN_TASK_STORE:
      return resetMainTaskStore();
    default:
      return state;
  }
};

export default reducer;
