import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import documentReducer, {
  DocumentStateType,
} from '../../store/document/reducer';
import signeeReducer, { SigneeStateType } from '../../store/signee/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import signatureReducer, { SignatureStateType } from '../../store/sign/reducer';
import groupReducer, { GroupStateType } from '../../store/group/reducer';
import jobTitleReducer, {
  JobTitleStateType,
} from '../../store/jobTitle/reducer';
import mainTaskReducer, {
  MainTaskStateType,
} from '../../store/mainTask/reducer';
import taskReducer, { TaskStateType } from '../../store/task/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import taskTemplateReducer, {
  TaskTemplateStateType,
} from '../../store/taskTemplate/reducer';

export type StoreState = {
  auth: AuthStateType;
  document: DocumentStateType;
  signee: SigneeStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  company: CompanyStateType;
  user: UserStateType;
  sign: SignatureStateType;
  group: GroupStateType;
  jobTitle: JobTitleStateType;
  mainTask: MainTaskStateType;
  task: TaskStateType;
  taskTemplate: TaskTemplateStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  document: documentReducer,
  signee: signeeReducer,
  translation: translationReducer,
  language: languageReducer,
  company: companyReducer,
  user: userReducer,
  sign: signatureReducer,
  group: groupReducer,
  jobTitle: jobTitleReducer,
  mainTask: mainTaskReducer,
  task: taskReducer,
  taskTemplate: taskTemplateReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
