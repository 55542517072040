import React, { useEffect, useMemo, useState } from 'react';
import styles from './MenuSubItemButton.module.scss';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { NavigationItem } from '../../../Layout/Layout';
import { Icon } from '@iconify/react';
import SubSubItem from '../SubSubItems/SubSubItem';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getLocaleUrl } from '../../../../utility/url/urlHelper';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Locale } from '../../../../domain/Translation';

type Props = {
  navItem: NavigationItem;
  selectedLocale: Locale;
};

const ICON_SIZE = '1rem';

const MenuSubItemButton = ({ navItem, selectedLocale }: Props) => {
  const [isSubSubMenuOpen, setSubSubMenuOpen] = useState(false);

  const hasItems =
    navItem?.subNavigations && navItem?.subNavigations?.length >= 1;

  const icon = isSubSubMenuOpen
    ? 'solar:alt-arrow-down-linear'
    : 'solar:alt-arrow-right-linear';

  const isActive = useMemo(
    () =>
      [
        navItem.to,
        ...(navItem.subNavigations || []).map((subNav) => subNav.to),
      ].some((path) =>
        window.location.href.includes(getLocaleUrl(path, selectedLocale)),
      ),
    [navItem, selectedLocale],
  );

  useEffect(() => {
    if (isActive) {
      setSubSubMenuOpen(true);
    }
  }, []);

  if (hasItems) {
    return (
      <div className={styles.subItemContainer}>
        <Button
          className={cx(styles.subItem, {
            [styles.activeSubItem]: isActive,
            [styles.withItems]: true,
          })}
          role="button"
          onClick={() => setSubSubMenuOpen(!isSubSubMenuOpen)}
        >
          <div className={styles.detailsContainer}>
            <FontAwesomeIcon
              icon={navItem.icon as IconProp}
              className={styles.navigationIcon}
              fixedWidth
            />
            <div className={styles.navigationText}>{navItem.label}</div>
          </div>
          <Icon
            className={cx(styles.subItemButton, {
              [styles.isOpen]: isSubSubMenuOpen,
            })}
            icon={icon}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </Button>
        <div
          className={cx(styles.subSubItemContainer, {
            [styles.isActive]: hasItems && isSubSubMenuOpen,
          })}
        >
          {navItem?.subNavigations?.map((subNavigation) => (
            <SubSubItem
              key={subNavigation.label + subNavigation.to}
              subNavigation={subNavigation}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <Button sx={{ padding: 0 }}>
      <div className={styles.subItemContainer}>
        <NavLink
          to={getLocaleUrl(navItem.to, selectedLocale)}
          className={({ isActive }) =>
            cx(styles.subItem, {
              [styles.activeSubItem]: isActive,
            })
          }
        >
          <FontAwesomeIcon
            icon={navItem.icon as IconProp}
            className={styles.navigationIcon}
            fixedWidth
          />
          <div className={styles.navigationText}>{navItem.label}</div>
        </NavLink>
      </div>
    </Button>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(MenuSubItemButton);
