import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company, CompaniesList } from '../../domain/Company';

export const fetchCompaniesStart = () => ({
  type: actionTypes.FETCH_COMPANIES_START,
});

export const fetchCompaniesSuccess = (companiesList: CompaniesList) => ({
  type: actionTypes.FETCH_COMPANIES_SUCCESS,
  companiesList,
});

export const fetchCompaniesFail = (companiesError: HttpError) => ({
  type: actionTypes.FETCH_COMPANIES_FAIL,
  companiesError,
});

export const fetchCompanyOptionsStart = () => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_START,
});

export const fetchCompanyOptionsSuccess = (companyOptions: Company[]) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS,
  companyOptions,
});

export const fetchCompanyOptionsFail = (companyOptionsError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_FAIL,
  companyOptionsError,
});

export const fetchCompanyStart = () => ({
  type: actionTypes.FETCH_COMPANY_START,
});

export const fetchCompanySuccess = (company: Company) => ({
  type: actionTypes.FETCH_COMPANY_SUCCESS,
  company,
});

export const fetchCompanyFail = (companyError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_FAIL,
  companyError,
});

export const createCompanyStart = () => ({
  type: actionTypes.CREATE_COMPANY_START,
});

export const createCompanySuccess = (createdCompany: Company) => ({
  type: actionTypes.CREATE_COMPANY_SUCCESS,
  createdCompany,
});

export const createCompanyFail = (companyCreateError: HttpError) => ({
  type: actionTypes.CREATE_COMPANY_FAIL,
  companyCreateError,
});

export const updateCompanyStart = () => ({
  type: actionTypes.UPDATE_COMPANY_START,
});

export const updateCompanySuccess = () => ({
  type: actionTypes.UPDATE_COMPANY_SUCCESS,
});

export const updateCompanyFail = (companyUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_COMPANY_FAIL,
  companyUpdateError,
});

export const toggleCompanyStatusStart = () => ({
  type: actionTypes.TOGGLE_COMPANY_STATUS_START,
});

export const toggleCompanyStatusSuccess = () => ({
  type: actionTypes.TOGGLE_COMPANY_STATUS_SUCCESS,
});

export const toggleCompanyStatusFail = (
  toggleCompanyStatusError: HttpError,
) => ({
  type: actionTypes.TOGGLE_COMPANY_STATUS_FAIL,
  toggleCompanyStatusError,
});

export const setSelectedCompany = (selectedCompany: number) => {
  localStorage.setItem('selectedCompany', selectedCompany.toString());
  return {
    type: actionTypes.SET_SELECTED_COMPANY,
    selectedCompany,
  };
};

export const resetCompanyStore = () => ({
  type: actionTypes.RESET_COMPANY_STORE,
});
