import { Company } from './Company';

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: Roles;
  active: boolean;
  deletedAt: string | null;
  partnerCompanies: Company[];
}

export interface UsersList {
  result: User[];
  total: number;
}

export enum Roles {
  // eslint-disable-next-line no-unused-vars
  ADMIN = 'ADMIN',
  // eslint-disable-next-line no-unused-vars
  OWNER = 'OWNER',
  // eslint-disable-next-line no-unused-vars
  MANAGER = 'MANAGER',
  // eslint-disable-next-line no-unused-vars
  USER = 'USER',
  // eslint-disable-next-line no-unused-vars
  PARTNER = 'PARTNER',
}
