import Router from './config/Router/Router';
import StoreProvider from './config/StoreProvider/StoreProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { ToastContainer } from 'react-toastify';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const App = () => {
  return (
    <GoogleOAuthProvider clientId="446020457477-on9l2k85i8vd57gek9fe6kpe4rfe3hu9.apps.googleusercontent.com">
      <StoreProvider>
        <Router />
        <ToastContainer />
      </StoreProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
