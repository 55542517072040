import { HttpError } from '../../config/Axios/axios-instance';
import _ from 'lodash';
import { translate } from '../messageTranslator/translate';
import { IntlShape } from 'react-intl';

export const getGlobalError = (error: HttpError) => {
  if (_.isArray(error)) {
    return null;
  }

  return error?.toString();
};

export const getTranslatedError = (error: HttpError, intl: IntlShape) => {
  if (!error) {
    return null;
  }

  return translate(intl, error.toString(), error.toString());
};
