import React, { useState } from 'react';
import styles from './MenuSubItems.module.scss';
import cx from 'classnames';
import { NavigationItem } from '../../Layout/Layout';
import MenuSubItemButton from './MenuSubItemButton/MenuSubItemButton';
import { Roles } from '../../../domain/User';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';

type Props = {
  sidebarItems: NavigationItem[];
  label: string;
  selectedRole: Roles | null;
};

const MenuSubItems = ({ sidebarItems, label, selectedRole }: Props) => {
  const [isClosed, setIsClosed] = useState(false);

  return (
    <div className={styles.menuItems}>
      <h2
        className={styles.menuItemsTitle}
        onClick={() => setIsClosed(!isClosed)}
      >
        {label}
      </h2>
      <div
        className={cx(styles.menuSubItemsContainer, {
          [styles.isClosed]: isClosed,
        })}
      >
        <div className={styles.menuSubItems}>
          {sidebarItems
            .filter((item) => selectedRole && item.roles.includes(selectedRole))
            .map((navItem) => (
              <MenuSubItemButton
                navItem={navItem}
                key={navItem.to + navItem.label}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedRole: state.auth.role,
});

export default connect(mapStateToProps)(MenuSubItems);
