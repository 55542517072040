import * as actionTypes from './actionTypes';
import { GroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Group } from '../../domain/Group';
import { ListResults } from '../../common/List/List';

export type GroupStateType = {
  groupList: Group[] | null;
  groupListLoading: boolean;
  groupListError: HttpError;
  groupDocumentsList: ListResults<Group> | null;
  groupDocumentsLoading: boolean;
  groupDocumentsError: HttpError;
};

export type GroupActionType = GroupStateType & {
  type: GroupActionTypes;
};

export const initialState: GroupStateType = {
  groupList: null,
  groupListLoading: true,
  groupListError: null,
  groupDocumentsList: null,
  groupDocumentsLoading: false,
  groupDocumentsError: null,
};

const fetchGroupsStart = (state: GroupStateType): GroupStateType => ({
  ...state,
  groupListLoading: true,
});

const fetchGroupsSuccess = (
  state: GroupStateType,
  action: GroupActionType,
): GroupStateType => ({
  ...state,
  groupList: action.groupList,
  groupListLoading: false,
  groupListError: null,
});

const fetchGroupsFail = (
  state: GroupStateType,
  action: GroupActionType,
): GroupStateType => ({
  ...state,
  groupListError: action.groupListError,
  groupListLoading: false,
});

const fetchGroupDocumentsStart = (state: GroupStateType): GroupStateType => ({
  ...state,
  groupDocumentsLoading: true,
});

const fetchGroupDocumentsSuccess = (
  state: GroupStateType,
  action: GroupActionType,
): GroupStateType => ({
  ...state,
  groupDocumentsList: action.groupDocumentsList,
  groupDocumentsLoading: false,
  groupDocumentsError: null,
});

const fetchGroupDocumentsFail = (
  state: GroupStateType,
  action: GroupActionType,
): GroupStateType => ({
  ...state,
  groupDocumentsError: action.groupDocumentsError,
  groupDocumentsLoading: false,
});

const resetGroupStore = (): GroupStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: GroupActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_GROUPS_START:
      return fetchGroupsStart(state);
    case actionTypes.FETCH_GROUPS_SUCCESS:
      return fetchGroupsSuccess(state, action);
    case actionTypes.FETCH_GROUPS_FAIL:
      return fetchGroupsFail(state, action);
    case actionTypes.FETCH_GROUP_DOCUMENTS_START:
      return fetchGroupDocumentsStart(state);
    case actionTypes.FETCH_GROUP_DOCUMENTS_SUCCESS:
      return fetchGroupDocumentsSuccess(state, action);
    case actionTypes.FETCH_GROUP_DOCUMENTS_FAIL:
      return fetchGroupDocumentsFail(state, action);
    case actionTypes.RESET_GROUP_STORE:
      return resetGroupStore();
    default:
      return state;
  }
};

export default reducer;
