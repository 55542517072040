import React, { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import * as languageService from '../../store/language/service';
import * as userService from '../../store/user/service';
import { Language } from '../../domain/Language';
import { IntlProvider } from 'react-intl';
import { Roles } from '../../domain/User';
import { DEFAULT_LOCALE } from '../constants';
import { selectLocale } from '../../store/auth/actions';
import 'moment/locale/lt';
import 'moment/locale/en-gb';
import moment from 'moment';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';

const Login = React.lazy(() => import('../../pages/Admin/Login/Login'));
const Documents = React.lazy(
  () => import('../../pages/Admin/Documents/Documents'),
);
const DocumentDetails = React.lazy(
  () => import('../../pages/Admin/DocumentDetails/DocumentDetails'),
);
const DocumentCreate = React.lazy(
  () => import('../../pages/Admin/DocumentCreate/DocumentCreate'),
);
const DocumentEdit = React.lazy(
  () => import('../../pages/Admin/DocumentEdit/DocumentEdit'),
);
const Signees = React.lazy(() => import('../../pages/Admin/Signees/Signees'));
const SigneeCreate = React.lazy(
  () => import('../../pages/Admin/SigneeCreate/SigneeCreate'),
);
const SigneeEdit = React.lazy(
  () => import('../../pages/Admin/SigneeEdit/SigneeEdit'),
);
const Companies = React.lazy(
  () => import('../../pages/Admin/Companies/Companies'),
);
const CompanyCreate = React.lazy(
  () => import('../../pages/Admin/CompanyCreate/CompanyCreate'),
);
const CompanyEdit = React.lazy(
  () => import('../../pages/Admin/CompanyEdit/CompanyEdit'),
);
const Users = React.lazy(() => import('../../pages/Admin/Users/Users'));
const PasswordRemind = React.lazy(
  () => import('../../pages/Admin/PasswordRemind/PasswordRemind'),
);
const PasswordReset = React.lazy(
  () => import('../../pages/Admin/PasswordReset/PasswordReset'),
);
const Register = React.lazy(
  () => import('../../pages/Admin/Register/Register'),
);
const JobTitles = React.lazy(
  () => import('../../pages/Admin/JobTitles/JobTitles'),
);
const JobTitleCreate = React.lazy(
  () => import('../../pages/Admin/JobTitleCreate/JobTitleCreate'),
);
const JobTitleEdit = React.lazy(
  () => import('../../pages/Admin/JobTitleEdit/JobTitleEdit'),
);
const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);
const MainTasks = React.lazy(
  () => import('../../pages/Admin/MainTasks/MainTasks'),
);
const MainTaskCreate = React.lazy(
  () => import('../../pages/Admin/MainTaskCreate/MainTaskCreate'),
);
const Tasks = React.lazy(() => import('../../pages/Admin/Tasks/Tasks'));
const TaskDetails = React.lazy(
  () => import('../../pages/Admin/TaskDetails/TaskDetails'),
);
const Export = React.lazy(() => import('../../pages/Admin/Export/Export'));
const MainTaskDetails = React.lazy(
  () => import('../../pages/Admin/MainTaskDetails/MainTaskDetailsPage'),
);
const UserDocuments = React.lazy(
  () => import('../../pages/Admin/UserDocuments/UserDocuments'),
);
const TaskTemplates = React.lazy(
  () => import('../../pages/Admin/TaskTemplates/TaskTemplates'),
);
const TaskTemplateCreate = React.lazy(
  () => import('../../pages/Admin/TaskTemplateCreate/TaskTemplateCreate'),
);
const TaskTemplateEdit = React.lazy(
  () => import('../../pages/Admin/TaskTemplateEdit/TaskTemplateEdit'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  onLanguageInit: (locale: string) => void;
  language: Language | null;
  onFetchCurrentUser: () => void;
  isCurrentUserLoading: boolean;
  selectedCompany: number | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  selectedRole: Roles | null;
};

const ALLOWED_LOCALES = ['lt', 'en', 'ru'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  onLanguageInit,
  language,
  onFetchCurrentUser,
  isCurrentUserLoading,
  selectedCompany,
  onLanguagesInit,
  onSelectLocale,
  selectedLocale,
  languages,
  selectedRole,
}: Props) => {
  const locale =
    window.location?.pathname?.substring(1)?.split('/')?.[0].toLowerCase() ??
    DEFAULT_LOCALE;

  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageInit(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageInit(parsedLocale);
    onLanguagesInit();
    onSelectLocale(parsedLocale);
  }, [parsedLocale]);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  const currentLanguage = useMemo(() => {
    const foundLanguage = languages?.find(
      (singleLanguage) => singleLanguage.locale === selectedLocale,
    );

    return foundLanguage ?? language;
  }, [languages, selectedLocale]);

  const mappedTranslations = currentLanguage?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  return (
    <BrowserRouter basename="/">
      {isInitCompleted &&
      !isCurrentUserLoading &&
      language &&
      currentLanguage ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={currentLanguage?.locale ?? language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="lt"
        >
          <Suspense fallback={<Loader isLoading isFullScreen />}>
            <Layout isAuthenticated={isAuthenticated}>
              <Routes>
                {!isAuthenticated && (
                  <>
                    <Route
                      path={getLocaleUrl(routes.login, selectedLocale)}
                      element={<Login />}
                    />
                    <Route
                      path={getLocaleUrl(routes.passwordRemind, selectedLocale)}
                      element={<PasswordRemind />}
                    />
                    <Route
                      path={getLocaleUrl(routes.passwordReset, selectedLocale)}
                      element={<PasswordReset />}
                    />
                    <Route
                      path={getLocaleUrl(routes.register, selectedLocale)}
                      element={<Register />}
                    />
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to={getLocaleUrl(routes.login, selectedLocale)}
                        />
                      }
                    />
                  </>
                )}
                {isAuthenticated && selectedRole && (
                  <>
                    {selectedCompany && selectedRole !== Roles.USER && (
                      <>
                        <Route
                          path={getLocaleUrl(
                            routes.documents.create,
                            selectedLocale,
                          )}
                          element={<DocumentCreate />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.documents.edit,
                            selectedLocale,
                          )}
                          element={<DocumentEdit />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.documents.list,
                            selectedLocale,
                          )}
                          element={<Documents />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.documents.details,
                            selectedLocale,
                          )}
                          element={<DocumentDetails />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.signees.create,
                            selectedLocale,
                          )}
                          element={<SigneeCreate />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.signees.edit,
                            selectedLocale,
                          )}
                          element={<SigneeEdit />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.signees.list,
                            selectedLocale,
                          )}
                          element={<Signees />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.jobTitles.create,
                            selectedLocale,
                          )}
                          element={<JobTitleCreate />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.jobTitles.edit,
                            selectedLocale,
                          )}
                          element={<JobTitleEdit />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.jobTitles.list,
                            selectedLocale,
                          )}
                          element={<JobTitles />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.mainTasks.list,
                            selectedLocale,
                          )}
                          element={<MainTasks />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.mainTasks.create,
                            selectedLocale,
                          )}
                          element={<MainTaskCreate />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.mainTasks.details,
                            selectedLocale,
                          )}
                          element={<MainTaskDetails />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.taskTemplates.list,
                            selectedLocale,
                          )}
                          element={<TaskTemplates />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.taskTemplates.create,
                            selectedLocale,
                          )}
                          element={<TaskTemplateCreate />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.taskTemplates.edit,
                            selectedLocale,
                          )}
                          element={<TaskTemplateEdit />}
                        />
                        <Route
                          path={getLocaleUrl(routes.export, selectedLocale)}
                          element={<Export />}
                        />
                      </>
                    )}

                    {selectedRole === Roles.ADMIN && (
                      <>
                        <Route
                          path={getLocaleUrl(
                            routes.companies.list,
                            selectedLocale,
                          )}
                          element={<Companies />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.companies.create,
                            selectedLocale,
                          )}
                          element={<CompanyCreate />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.companies.edit,
                            selectedLocale,
                          )}
                          element={<CompanyEdit />}
                        />
                        <Route
                          path={getLocaleUrl(routes.users.list, selectedLocale)}
                          element={<Users />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.translations,
                            selectedLocale,
                          )}
                          element={<TranslationsPage />}
                        />
                        <Route
                          path="*"
                          element={
                            <Navigate
                              to={getLocaleUrl(
                                selectedCompany
                                  ? routes.mainTasks.list
                                  : routes.companies.list,
                                selectedLocale,
                              )}
                            />
                          }
                        />
                      </>
                    )}

                    {selectedRole === Roles.ADMIN ||
                      (selectedRole === Roles.PARTNER && (
                        <>
                          <Route
                            path={getLocaleUrl(
                              routes.companies.list,
                              selectedLocale,
                            )}
                            element={<Companies />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.companies.create,
                              selectedLocale,
                            )}
                            element={<CompanyCreate />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.companies.edit,
                              selectedLocale,
                            )}
                            element={<CompanyEdit />}
                          />
                          <Route
                            path="*"
                            element={
                              <Navigate
                                to={getLocaleUrl(
                                  selectedCompany
                                    ? routes.mainTasks.list
                                    : routes.companies.list,
                                  selectedLocale,
                                )}
                              />
                            }
                          />
                        </>
                      ))}

                    {selectedRole !== Roles.ADMIN && (
                      <>
                        <Route
                          path={getLocaleUrl(routes.tasks.list, selectedLocale)}
                          element={<Tasks />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.tasks.details,
                            selectedLocale,
                          )}
                          element={<TaskDetails />}
                        />
                        <Route
                          path={getLocaleUrl(
                            routes.myDocuments.list,
                            selectedLocale,
                          )}
                          element={<UserDocuments />}
                        />
                        <Route
                          path="*"
                          element={
                            <Navigate
                              to={getLocaleUrl(
                                routes.tasks.list,
                                selectedLocale,
                              )}
                            />
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </Routes>
            </Layout>
          </Suspense>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  language: state.language.language,
  isCurrentUserLoading: state.user.currentUserLoading,
  selectedCompany: state.company.selectedCompany,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  selectedRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onLanguageInit: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
