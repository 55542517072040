import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User, UsersList } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { DocumentVersion } from '../../domain/DocumentVersion';

export type UserStateType = {
  ownersList: UsersList | null;
  ownersLoading: boolean;
  ownersError: HttpError;
  usersList: UsersList | null;
  usersLoading: boolean;
  usersError: HttpError;
  userCreateLoading: boolean;
  userCreateError: HttpError;
  userCreateSuccess: boolean;
  createdUser: User | null;
  userUpdateLoading: boolean;
  userUpdateError: HttpError;
  userUpdateSuccess: boolean;
  userDeleteLoading: boolean;
  userDeleteError: HttpError;
  userDeleteSuccess: boolean;
  usersListUpdateNeeded: boolean;
  currentUser: User | null;
  currentUserLoading: boolean;
  myDocuments: ListResults<DocumentVersion> | null;
  myDocumentsLoading: boolean;
  myDocumentsError: HttpError;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
};

export const initialState: UserStateType = {
  ownersList: null,
  ownersLoading: true,
  ownersError: null,
  userCreateError: null,
  userCreateLoading: false,
  userCreateSuccess: false,
  createdUser: null,
  userDeleteError: null,
  userDeleteLoading: false,
  userDeleteSuccess: false,
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  usersError: null,
  usersList: null,
  usersLoading: false,
  usersListUpdateNeeded: false,
  currentUser: null,
  currentUserLoading: false,
  myDocuments: null,
  myDocumentsError: null,
  myDocumentsLoading: false,
};

const fetchCurrentUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const fetchOwnersStart = (state: UserStateType): UserStateType => ({
  ...state,
  ownersLoading: true,
});

const fetchOwnersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  ownersList: action.ownersList,
  ownersLoading: false,
  ownersError: null,
});

const fetchOwnersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  ownersError: action.ownersError,
  ownersLoading: false,
});

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
});

const fetchUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersList: action.usersList,
  usersLoading: false,
  usersError: null,
  usersListUpdateNeeded: false,
});

const fetchUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const fetchMyDocumentsStart = (state: UserStateType): UserStateType => ({
  ...state,
  myDocumentsLoading: true,
});

const fetchMyDocumentsSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  myDocuments: action.myDocuments,
  myDocumentsLoading: false,
  myDocumentsError: null,
});

const fetchMyDocumentsFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  myDocumentsError: action.myDocumentsError,
  myDocumentsLoading: false,
});

const createUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
});

const createUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: null,
  userCreateSuccess: true,
  usersListUpdateNeeded: true,
  createdUser: action.createdUser,
});

const createUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: action.userCreateError,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: null,
  userUpdateSuccess: true,
  usersListUpdateNeeded: true,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: action.userUpdateError,
});

const deleteUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: true,
});

const deleteUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: null,
  userDeleteSuccess: true,
  usersListUpdateNeeded: true,
});

const deleteUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: action.userDeleteError,
});

const resetCreateUserStore = (state: UserStateType): UserStateType => ({
  ...state,
  createdUser: null,
});

const resetMyDocumentsStore = (state: UserStateType): UserStateType => ({
  ...state,
  myDocuments: null,
  myDocumentsLoading: false,
  myDocumentsError: null,
});

const resetUserStore = (state: UserStateType): UserStateType => ({
  ownersList: null,
  ownersLoading: true,
  ownersError: null,
  userCreateError: null,
  userCreateLoading: false,
  userCreateSuccess: false,
  userDeleteError: null,
  userDeleteLoading: false,
  userDeleteSuccess: false,
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  usersError: null,
  usersList: null,
  usersLoading: false,
  usersListUpdateNeeded: false,
  currentUser: state.currentUser,
  currentUserLoading: false,
  myDocumentsLoading: false,
  myDocumentsError: null,
  myDocuments: null,
  createdUser: null,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_OWNERS_START:
      return fetchOwnersStart(state);
    case actionTypes.FETCH_OWNERS_SUCCESS:
      return fetchOwnersSuccess(state, action);
    case actionTypes.FETCH_OWNERS_FAIL:
      return fetchOwnersFail(state, action);
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.FETCH_ME_START:
      return fetchCurrentUserStart(state);
    case actionTypes.FETCH_ME_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_ME_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.FETCH_MY_DOCUMENTS_START:
      return fetchMyDocumentsStart(state);
    case actionTypes.FETCH_MY_DOCUMENTS_SUCCESS:
      return fetchMyDocumentsSuccess(state, action);
    case actionTypes.FETCH_MY_DOCUMENTS_FAIL:
      return fetchMyDocumentsFail(state, action);
    case actionTypes.RESET_MY_DOCUMENTS_STORE:
      return resetMyDocumentsStore(state);
    case actionTypes.RESET_CREATED_USER:
      return resetCreateUserStore(state);
    case actionTypes.RESET_USER_STORE:
      return resetUserStore(state);
    default:
      return state;
  }
};

export default reducer;
