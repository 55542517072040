import React from 'react';
import styles from './SubSubItem.module.scss';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { SubNavigationItem } from '../../../Layout/Layout';
import { Icon } from '@iconify/react';
import { Button } from '@mui/material';
import { getLocaleUrl } from '../../../../utility/url/urlHelper';
import { connect } from 'react-redux';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { Locale } from '../../../../domain/Translation';

type Props = {
  subNavigation: SubNavigationItem;
  selectedLocale: Locale;
};

const ICON_SIZE = '0.35rem';
const ICON_COLOR = '#919eab';

const SubSubItem = ({ subNavigation, selectedLocale }: Props) => {
  const currentUrlSegments = window.location.href.split('/');

  const targetUrlSegments = getLocaleUrl(
    subNavigation.to,
    selectedLocale,
  ).split('/');

  const isCurrentPageActive =
    currentUrlSegments[currentUrlSegments.length - 1] ===
    targetUrlSegments[targetUrlSegments.length - 1];

  return (
    <Button variant="text" sx={{ height: '1.875rem' }}>
      <NavLink
        to={getLocaleUrl(subNavigation.to, selectedLocale)}
        className={cx(styles.container, {
          [styles.activeSubItem]: isCurrentPageActive,
        })}
      >
        <Icon
          icon="ph:circle-fill"
          width={ICON_SIZE}
          height={ICON_SIZE}
          style={{ color: ICON_COLOR }}
        />
        <p>{subNavigation.label}</p>
      </NavLink>
    </Button>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(SubSubItem);
