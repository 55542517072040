import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Roles, User } from '../../domain/User';
import { DEFAULT_LOCALE } from '../../config/constants';
import { Locale } from '../../domain/Translation';

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginGoogleLoading: boolean;
  loginGoogleError: string | null;
  loginLoading: boolean;
  loginError: HttpError;
  remindLoading: boolean;
  remindError: HttpError;
  isRemindSuccess: boolean;
  resetPasswordLoading: boolean;
  resetPasswordError: HttpError;
  validateCodeLoading: boolean;
  validateCodeError: HttpError;
  validatedUser: User | null;
  registerLoading: boolean;
  registerError: HttpError;
  validateRegisterCodeLoading: boolean;
  validateRegisterCodeError: HttpError;
  selectedLocale: Locale;
  role: Roles | null;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  loginLoading: false,
  loginError: null,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
  resetPasswordLoading: false,
  resetPasswordError: null,
  validateCodeLoading: false,
  validateCodeError: null,
  validatedUser: null,
  registerError: null,
  registerLoading: false,
  validateRegisterCodeLoading: false,
  validateRegisterCodeError: null,
  selectedLocale:
    (localStorage.getItem('selectedLocale') as Locale) ?? DEFAULT_LOCALE,
  role: localStorage.getItem('selectedRole') as Roles,
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginGoogleFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginLoading: true,
});

const loginSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginError: null,
  loginLoading: false,
  isInitCompleted: true,
});

const loginFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginError: action.loginError,
  loginLoading: false,
  isInitCompleted: true,
});

const registerStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerLoading: true,
});

const registerSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  registerError: null,
  registerLoading: false,
  isInitCompleted: true,
});

const registerFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerError: action.registerError,
  registerLoading: false,
  isInitCompleted: true,
});

const remindStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindLoading: true,
  validatedUser: null,
  validateCodeError: null,
});

const remindSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindError: null,
  remindLoading: false,
  isRemindSuccess: true,
});

const remindFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  remindError: action.remindError,
  remindLoading: false,
});

const resetPasswordStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  resetPasswordLoading: true,
});

const resetPasswordSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  resetPasswordError: null,
  resetPasswordLoading: false,
});

const resetPasswordFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  resetPasswordError: action.resetPasswordError,
  resetPasswordLoading: false,
  isInitCompleted: true,
});

const validateCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  validateCodeLoading: true,
});

const validateCodeSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: null,
  validateCodeLoading: false,
  validatedUser: action.validatedUser,
});

const validateCodeFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: action.validateCodeError,
  validateCodeLoading: false,
});

const validateRegisterCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  validateRegisterCodeLoading: true,
});

const validateRegisterCodeSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateRegisterCodeError: null,
  validateRegisterCodeLoading: false,
  validatedUser: action.validatedUser,
});

const validateRegisterCodeFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateRegisterCodeError: action.validateRegisterCodeError,
  validateCodeLoading: false,
});

const setRole = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  if (action.role) {
    localStorage.setItem('selectedRole', action.role);
  }

  return {
    ...state,
    role: action.role,
  };
};

const selectLocale = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  localStorage.setItem('selectedLocale', action.selectedLocale);

  return {
    ...state,
    selectedLocale: action.selectedLocale,
  };
};

const logout = (): AuthStateType => ({
  ...initialState,
  isInitCompleted: true,
  selectedLocale: localStorage.getItem('selectedLocale') as Locale,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_GOOGLE_START:
      return loginGoogleStart(state);
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return loginGoogleSuccess(state);
    case actionTypes.LOGIN_GOOGLE_FAIL:
      return loginGoogleFail(state, action);
    case actionTypes.LOGIN_START:
      return loginStart(state);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.REGISTER_START:
      return registerStart(state);
    case actionTypes.REGISTER_SUCCESS:
      return registerSuccess(state);
    case actionTypes.REGISTER_FAIL:
      return registerFail(state, action);
    case actionTypes.REMIND_START:
      return remindStart(state);
    case actionTypes.REMIND_SUCCESS:
      return remindSuccess(state);
    case actionTypes.REMIND_FAIL:
      return remindFail(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.VALIDATE_CODE_START:
      return validateCodeStart(state);
    case actionTypes.VALIDATE_CODE_SUCCESS:
      return validateCodeSuccess(state, action);
    case actionTypes.VALIDATE_CODE_FAIL:
      return validateCodeFail(state, action);
    case actionTypes.VALIDATE_REGISTRATION_CODE_START:
      return validateRegisterCodeStart(state);
    case actionTypes.VALIDATE_REGISTRATION_CODE_SUCCESS:
      return validateRegisterCodeSuccess(state, action);
    case actionTypes.VALIDATE_REGISTRATION_CODE_FAIL:
      return validateRegisterCodeFail(state, action);
    case actionTypes.SELECT_LOCALE:
      return selectLocale(state, action);
    case actionTypes.SET_ROLE:
      return setRole(state, action);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
