export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAIL = 'FETCH_DOCUMENT_FAIL';

export const CREATE_DOCUMENT_START = 'CREATE_DOCUMENT_START';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAIL = 'CREATE_DOCUMENT_FAIL';

export const UPDATE_DOCUMENT_START = 'UPDATE_DOCUMENT_START';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL';

export const TOGGLE_ARCHIVE_DOCUMENT_START = 'TOGGLE_ARCHIVE_DOCUMENT_START';
export const TOGGLE_ARCHIVE_DOCUMENT_SUCCESS =
  'TOGGLE_ARCHIVE_DOCUMENT_SUCCESS';
export const TOGGLE_ARCHIVE_DOCUMENT_FAIL = 'TOGGLE_ARCHIVE_DOCUMENT_FAIL';

export const RESET_DOCUMENT_STORE = 'RESET_DOCUMENT_STORE';

export const RESET_DOCUMENT_CREATE_STATE = 'RESET_DOCUMENT_CREATE_STATE';

export type DocumentActionTypes =
  | typeof FETCH_DOCUMENT_START
  | typeof FETCH_DOCUMENT_SUCCESS
  | typeof FETCH_DOCUMENT_FAIL
  | typeof CREATE_DOCUMENT_START
  | typeof CREATE_DOCUMENT_SUCCESS
  | typeof CREATE_DOCUMENT_FAIL
  | typeof UPDATE_DOCUMENT_START
  | typeof UPDATE_DOCUMENT_SUCCESS
  | typeof UPDATE_DOCUMENT_FAIL
  | typeof TOGGLE_ARCHIVE_DOCUMENT_START
  | typeof TOGGLE_ARCHIVE_DOCUMENT_SUCCESS
  | typeof TOGGLE_ARCHIVE_DOCUMENT_FAIL
  | typeof RESET_DOCUMENT_CREATE_STATE
  | typeof RESET_DOCUMENT_STORE;
