import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Chip,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Switch,
  TextField as MuiTextField,
} from '@mui/material';
import styles from './TextField.module.scss';
import cx from 'classnames';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TimePicker from '@mui/lab/TimePicker';
import moment from 'moment';
import Alert from '../Alert/Alert';
import {
  faFileImport,
  faFileCircleExclamation,
  faFileCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDelayedSearch, {
  UseDelayedSearch,
} from '../../hooks/useDelayedSearch/useDelayedSearch';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import Button from '../Button/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReCAPTCHA from 'react-google-recaptcha';
import { Asset } from '../../domain/Asset';

type Props = {
  onChange?: (event: ChangeEvent<any>) => void;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onCheckboxChange?: (name: string, value: string) => void;
  onTimeChange?: (name: string, value: string) => void;
  onBlur?: (event: ChangeEvent<any>) => void;
  value?: string | File;
  label?: string | ReactNode;
  errors?: Array<string>;
  name: string;
  type?: string;
  placeholder?: string;
  inputProps?: any;
  className?: string;
  options?: Array<{ value: string; label: string; group: string }>;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  disabled?: boolean;
  helperText?: string;
  clearable?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  onSelectChange?: (value: string | string[], name: string) => void;
  isLoading?: boolean;
  onSearch?: (searchValue: string) => void;
  moreLabel?: string;
  creatable?: boolean;
  onCreate?: () => void;
  recaptchRef?: any;
  useGroupBy?: boolean;
  asset?: Asset | null;
  autoFocus?: boolean;
  limit?: number;
  onShowMoreClick?: () => void;
  minDate?: Date;
  maxDate?: Date;
};

const TextField = ({
  onChange,
  onBlur,
  value,
  label,
  errors,
  name,
  type,
  placeholder,
  inputProps,
  className,
  onFileChange,
  onCheckboxChange,
  options,
  labelPlacement,
  onTimeChange,
  disabled,
  helperText,
  clearable,
  multiple,
  disableCloseOnSelect,
  disableClearable,
  onSelectChange,
  isLoading,
  onSearch,
  moreLabel,
  creatable,
  onCreate,
  recaptchRef,
  useGroupBy,
  asset,
  autoFocus,
  limit,
  onShowMoreClick,
  minDate,
  maxDate,
}: Props) => {
  const intl = useIntl();
  const [autocompleteInput, setAutocompleteInput] = useState<string>('');
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const { setSearchValue }: UseDelayedSearch = useDelayedSearch(
    !!onSearch ? onSearch : () => {},
  );

  useEffect(() => {
    if (type === 'autocomplete') {
      setSearchValue(autocompleteInput);
    }
  }, [autocompleteInput]);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  if (type === 'autocomplete') {
    const getSelectionValue = () => {
      if (Array.isArray(value)) {
        return options?.filter((option) => value.includes(option.value));
      }

      if (multiple) {
        const values = value?.toString().split(',');
        return options?.filter(
          (option) =>
            !!values?.find(
              (singleValue) =>
                singleValue.toString() === option.value.toString(),
            ),
        );
      }

      return options?.find(
        (option) => option.value.toString() === value?.toString(),
      );
    };

    const getOptions = () => {
      if (!options) {
        return [];
      }

      if (moreLabel) {
        return [...options, { label: moreLabel, value: '', group: '' }];
      }

      return options;
    };

    const groupBy = (option: any) => option.group;

    const renderTags = (value: any[], getTagProps: Function) => {
      if (!limit) {
        return;
      }

      const numTags = value.length;

      return (
        <>
          {value.slice(0, limit).map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              label={option.label}
            />
          ))}

          {numTags > limit && (
            <Chip
              className={styles.showMoreChip}
              label={translate(intl, 'TEXT_FIELD.SHOW_MORE').replace(
                ':count',
                (numTags - limit).toString(),
              )}
              onClick={onShowMoreClick}
            />
          )}
        </>
      );
    };

    return (
      <MuiAutocomplete
        id={name}
        className={styles.autocomplete}
        multiple={multiple}
        groupBy={useGroupBy ? groupBy : undefined}
        options={getOptions()}
        getOptionLabel={(option: any) => option.label}
        value={getSelectionValue() ?? null}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        disableClearable={disableClearable}
        inputValue={multiple ? autocompleteInput : undefined}
        getOptionDisabled={(option) => option.value === ''}
        limitTags={limit}
        renderTags={limit ? renderTags : undefined}
        onChange={(_: any, values: any) => {
          setAutocompleteInput('');
          if (Array.isArray(values)) {
            return onSelectChange?.(
              (values as any[]).map(({ value }) => value),
              name,
            );
          }

          onSelectChange?.(values?.value || '', name);
        }}
        onBlur={onBlur}
        loading={isLoading}
        renderInput={(params: any) => (
          <MuiTextField
            {...params}
            label={label}
            placeholder={placeholder}
            helperText={errors && errors.length > 0 ? errors[0] : ''}
            error={errors && errors.length > 0}
            name={name}
            className={cx(styles.textField)}
            onChange={(event) => setAutocompleteInput(event.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {creatable && (
                    <Button buttonVariant="text" onClick={() => onCreate?.()}>
                      {translate(intl, 'TEXT_FIELD.CREATE')}
                    </Button>
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  if (type === 'file') {
    const getIcon = () => {
      if (errors && errors.length > 0) {
        return faFileCircleExclamation;
      }

      if (value && (!errors || errors.length === 0)) {
        return faFileCircleCheck;
      }

      return faFileImport;
    };

    return (
      <div className={styles.fileField}>
        <input
          color="primary"
          type="file"
          onChange={onFileChange}
          ref={uploadInputRef}
          name={name}
          accept=".pdf"
        />
        <div className={styles.fileContainer}>
          <div
            className={styles.fileSelectedContainer}
            onClick={handleUploadCLick}
          >
            <div className={styles.innerFileSelectContainer}>
              <FontAwesomeIcon
                className={cx(styles.innerFileIcon, {
                  [styles.errorFile]: errors && errors.length > 0,
                  [styles.successFile]:
                    value && (!errors || errors.length === 0),
                })}
                icon={getIcon()}
                fixedWidth
                size="2x"
              />
              <span className={styles.innerFileSelectLabel}>
                {value && (!errors || errors.length === 0)
                  ? translate(intl, 'GENERAL.READY_TO_UPLOAD')
                  : translate(intl, 'GENERAL.UPLOAD_DOCUMENT')}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.label}>
          <h5>{label}</h5>
          <p>{translate(intl, 'VALIDATION.MAX_FILE_SIZE')}</p>
          <p>{translate(intl, 'VALIDATION.ALLOWED_EXTENSIONS')}</p>
          <p className={styles.selectedFileLabel}>
            {value && (
              <FontAwesomeIcon
                icon={faFileImport as IconProp}
                fixedWidth
                size="sm"
                className={styles.selectedFileIcon}
              />
            )}
            <span>
              {value && value instanceof File
                ? value.name
                : asset?.name ?? translate(intl, 'VALIDATION.NO_FILE_SELECTED')}
            </span>
          </p>
          {errors &&
            errors.map((error, index) => (
              <div className={styles.fileError} key={`file-error-${index}`}>
                {error}
              </div>
            ))}
          {helperText && <p>{helperText}</p>}
        </div>
      </div>
    );
  }

  if (type === 'currency') {
    return (
      <>
        {helperText && <Alert variant="warning">{helperText}</Alert>}
        <MuiTextField
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          error={errors && errors.length > 0}
          variant="outlined"
          name={name}
          helperText={errors && errors.length > 0 ? errors[0] : ''}
          className={cx(styles.textField, styles.currencyInput, className)}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
      </>
    );
  }

  if (type === 'time') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          ampm={false}
          value={
            typeof value === 'string' ? moment(value, 'HH:mm').toDate() : ''
          }
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(name, moment(newValue?.toString()).format('HH:mm'));
          }}
          label={label}
          disabled={disabled}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.timeInput, className)}
              onBlur={onBlur}
              helperText={errors && errors.length > 0 ? errors[0] : ''}
              error={errors && errors.length > 0}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'staticdate') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={errors && errors.length > 0 ? errors[0] : ''}
              error={errors && errors.length > 0}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'datetime') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          views={['year', 'month', 'day', 'hours', 'minutes']}
          label={label}
          value={value}
          minDateTime={moment('1900-01-01').toDate()}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD HH:mm')
                  : '',
              );
          }}
          mask="____-__-__ __:__"
          disabled={disabled}
          inputFormat="yyyy-MM-dd HH:mm"
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={errors && errors.length > 0 ? errors[0] : ''}
              error={errors && errors.length > 0}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'date') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year', 'month', 'day']}
          label={label}
          minDate={minDate ?? moment('1900-01-01').toDate()}
          maxDate={maxDate}
          value={value}
          onChange={(newValue, keyboardInputValue) => {
            if (!onTimeChange) {
              return;
            }

            const isValidInput = keyboardInputValue
              ? moment(keyboardInputValue, 'YYYY-MM-DD', true).isValid()
              : true;

            onTimeChange(
              name,
              newValue
                ? isValidInput
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : 'Invalid date'
                : '',
            );
          }}
          disabled={disabled}
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
          renderInput={(params) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={errors && errors.length > 0 ? errors[0] : ''}
              error={errors && errors.length > 0}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'checkbox') {
    return (
      <FormControlLabel
        className={cx(styles.switcherContainer, className)}
        labelPlacement={labelPlacement}
        control={
          <Switch
            name={name}
            checked={value === '1'}
            onChange={() =>
              onCheckboxChange &&
              onCheckboxChange(name, value === '0' ? '1' : '0')
            }
          />
        }
        disabled={disabled}
        label={label}
      />
    );
  }

  if (type === 'select') {
    return (
      <MuiTextField
        value={value}
        select
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        error={errors && errors.length > 0}
        variant="outlined"
        name={name}
        helperText={errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type="number"
        placeholder={placeholder}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )) ?? []}
      </MuiTextField>
    );
  }

  if (type === 'recaptcha') {
    return (
      <>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
          onChange={(value) =>
            onCheckboxChange && onCheckboxChange(name, value || '')
          }
          ref={recaptchRef}
          className={styles.recaptcha}
        />
        {errors &&
          errors.map((error, index) => (
            <div
              className={styles.repatchaError}
              key={`repatcha-error-${index}`}
            >
              {error}
            </div>
          ))}
      </>
    );
  }

  return (
    <>
      {helperText && <Alert variant="info">{helperText}</Alert>}
      <MuiTextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        error={errors && errors.length > 0}
        variant="outlined"
        name={name}
        helperText={errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type={type}
        placeholder={placeholder}
        InputProps={inputProps}
        multiline={type === 'textarea'}
        rows={5}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </>
  );
};

export default TextField;
