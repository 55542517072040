import * as actionTypes from './actionTypes';
import { SignatureActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type SignatureStateType = {
  remindSignaturesLoading: boolean;
  remindSignaturesError: HttpError;
};

export type SignatureActionType = SignatureStateType & {
  type: SignatureActionTypes;
};

export const initialState: SignatureStateType = {
  remindSignaturesLoading: false,
  remindSignaturesError: null,
};

const remindSignaturesStart = (
  state: SignatureStateType,
): SignatureStateType => ({
  ...state,
  remindSignaturesLoading: true,
});

const remindSignaturesSuccess = (
  state: SignatureStateType,
): SignatureStateType => ({
  ...state,
  remindSignaturesLoading: false,
  remindSignaturesError: null,
});

const remindSignaturesFail = (
  state: SignatureStateType,
  action: SignatureActionType,
): SignatureStateType => ({
  ...state,
  remindSignaturesLoading: false,
  remindSignaturesError: action.remindSignaturesError,
});

const reducer = (state = initialState, action: SignatureActionType) => {
  switch (action.type) {
    case actionTypes.REMIND_SIGNATURES_START:
      return remindSignaturesStart(state);
    case actionTypes.REMIND_SIGNATURES_SUCCESS:
      return remindSignaturesSuccess(state);
    case actionTypes.REMIND_SIGNATURES_FAIL:
      return remindSignaturesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
