export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAIL = 'FETCH_GROUPS_FAIL';

export const FETCH_GROUP_DOCUMENTS_START = 'FETCH_GROUP_DOCUMENTS_START';
export const FETCH_GROUP_DOCUMENTS_SUCCESS = 'FETCH_GROUP_DOCUMENTS_SUCCESS';
export const FETCH_GROUP_DOCUMENTS_FAIL = 'FETCH_GROUP_DOCUMENTS_FAIL';

export const RESET_GROUP_STORE = 'RESET_GROUP_STORE';

export type GroupActionTypes =
  | typeof FETCH_GROUPS_START
  | typeof FETCH_GROUPS_SUCCESS
  | typeof FETCH_GROUPS_FAIL
  | typeof FETCH_GROUP_DOCUMENTS_START
  | typeof FETCH_GROUP_DOCUMENTS_SUCCESS
  | typeof FETCH_GROUP_DOCUMENTS_FAIL
  | typeof RESET_GROUP_STORE;
