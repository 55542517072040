import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User, UsersList } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { DocumentVersion } from '../../domain/DocumentVersion';

export const fetchOwnersStart = () => ({
  type: actionTypes.FETCH_OWNERS_START,
});

export const fetchOwnersSuccess = (ownersList: UsersList) => ({
  type: actionTypes.FETCH_OWNERS_SUCCESS,
  ownersList,
});

export const fetchOwnersFail = (ownersError: HttpError) => ({
  type: actionTypes.FETCH_OWNERS_FAIL,
  ownersError,
});

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (usersList: UsersList) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  usersList,
});

export const fetchUsersFail = (usersError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  usersError,
});

export const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = (createdUser: User) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  createdUser,
});

export const createUserFail = (userCreateError: HttpError) => ({
  type: actionTypes.CREATE_USER_FAIL,
  userCreateError,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (userUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  userUpdateError,
});

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFail = (cityUserError: HttpError) => ({
  type: actionTypes.DELETE_USER_FAIL,
  cityUserError,
});

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_ME_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const fetchMyDocumentsStart = () => ({
  type: actionTypes.FETCH_MY_DOCUMENTS_START,
});

export const fetchMyDocumentsSuccess = (
  myDocuments: ListResults<DocumentVersion>,
) => ({
  type: actionTypes.FETCH_MY_DOCUMENTS_SUCCESS,
  myDocuments,
});

export const fetchMyDocumentsFail = (myDocumentsError: HttpError) => ({
  type: actionTypes.FETCH_MY_DOCUMENTS_FAIL,
  myDocumentsError,
});

export const resetMyDocumentsStore = () => ({
  type: actionTypes.RESET_MY_DOCUMENTS_STORE,
});

export const resetCreateUserStore = () => ({
  type: actionTypes.RESET_CREATED_USER,
});

export const resetUserStore = () => ({
  type: actionTypes.RESET_USER_STORE,
});
