export const FETCH_MAIN_TASKS_START = 'FETCH_MAIN_TASKS_START';
export const FETCH_MAIN_TASKS_SUCCESS = 'FETCH_MAIN_TASKS_SUCCESS';
export const FETCH_MAIN_TASKS_FAIL = 'FETCH_MAIN_TASKS_FAIL';

export const FETCH_MAIN_TASK_START = 'FETCH_MAIN_TASK_START';
export const FETCH_MAIN_TASK_SUCCESS = 'FETCH_MAIN_TASK_SUCCESS';
export const FETCH_MAIN_TASK_FAIL = 'FETCH_MAIN_TASK_FAIL';

export const FETCH_MAIN_TASK_SIGNEES_START = 'FETCH_MAIN_TASK_SIGNEES_START';
export const FETCH_MAIN_TASK_SIGNEES_SUCCESS =
  'FETCH_MAIN_TASK_SIGNEES_SUCCESS';
export const FETCH_MAIN_TASK_SIGNEES_FAIL = 'FETCH_MAIN_TASK_SIGNEES_FAIL';

export const FETCH_MAIN_TASK_DOCUMENTS_START =
  'FETCH_MAIN_TASK_DOCUMENTS_START';
export const FETCH_MAIN_TASK_DOCUMENTS_SUCCESS =
  'FETCH_MAIN_TASK_DOCUMENTS_SUCCESS';
export const FETCH_MAIN_TASK_DOCUMENTS_FAIL = 'FETCH_MAIN_TASK_DOCUMENTS_FAIL';

export const CREATE_MAIN_TASK_START = 'CREATE_MAIN_TASK_START';
export const CREATE_MAIN_TASK_SUCCESS = 'CREATE_MAIN_TASK_SUCCESS';
export const CREATE_MAIN_TASK_FAIL = 'CREATE_MAIN_TASK_FAIL';

export const DELETE_MAIN_TASK_START = 'DELETE_MAIN_TASK_START';
export const DELETE_MAIN_TASK_SUCCESS = 'DELETE_MAIN_TASK_SUCCESS';
export const DELETE_MAIN_TASK_FAIL = 'DELETE_MAIN_TASK_FAIL';

export const MAIN_TASK_RESEND_CODE_START = 'MAIN_TASK_RESEND_CODE_START';
export const MAIN_TASK_RESEND_CODE_SUCCESS = 'MAIN_TASK_RESEND_CODE_SUCCESS';
export const MAIN_TASK_RESEND_CODE_FAIL = 'MAIN_TASK_RESEND_CODE_FAIL';

export const RESET_MAIN_TASK_RESEND_CODE_STORE =
  'RESET_MAIN_TASK_RESEND_CODE_STORE';
export const RESET_MAIN_TASK_STORE = 'RESET_MAIN_TASK_STORE';

export type MainTaskActionTypes =
  | typeof FETCH_MAIN_TASKS_START
  | typeof FETCH_MAIN_TASKS_SUCCESS
  | typeof FETCH_MAIN_TASKS_FAIL
  | typeof FETCH_MAIN_TASK_START
  | typeof FETCH_MAIN_TASK_SUCCESS
  | typeof FETCH_MAIN_TASK_FAIL
  | typeof CREATE_MAIN_TASK_START
  | typeof CREATE_MAIN_TASK_SUCCESS
  | typeof CREATE_MAIN_TASK_FAIL
  | typeof RESET_MAIN_TASK_STORE
  | typeof DELETE_MAIN_TASK_START
  | typeof DELETE_MAIN_TASK_SUCCESS
  | typeof FETCH_MAIN_TASK_SIGNEES_START
  | typeof FETCH_MAIN_TASK_SIGNEES_SUCCESS
  | typeof FETCH_MAIN_TASK_SIGNEES_FAIL
  | typeof FETCH_MAIN_TASK_DOCUMENTS_START
  | typeof FETCH_MAIN_TASK_DOCUMENTS_SUCCESS
  | typeof FETCH_MAIN_TASK_DOCUMENTS_FAIL
  | typeof DELETE_MAIN_TASK_FAIL
  | typeof MAIN_TASK_RESEND_CODE_START
  | typeof MAIN_TASK_RESEND_CODE_SUCCESS
  | typeof MAIN_TASK_RESEND_CODE_FAIL
  | typeof RESET_MAIN_TASK_RESEND_CODE_STORE;
