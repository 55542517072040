import axios from '../../config/Axios/axios-instance';
import {
  loginGoogleStart,
  loginGoogleSuccess,
  loginGoogleFail,
  logout,
  remindSuccess,
  resetPasswordStart,
  resetPasswordSuccess,
  validateCodeStart,
  loginSuccess,
  remindStart,
  validateCodeSuccess,
  loginStart,
  loginFail,
  validateCodeFail,
  remindFail,
  resetPasswordFail,
  validateRegistrationCodeStart,
  validateRegistrationCodeSuccess,
  validateRegistrationCodeFail,
  registerStart,
  registerSuccess,
  registerFail,
  setRole,
} from './actions';
import { Dispatch } from 'redux';
import { resetDocumentStore } from '../document/actions';
import { resetSigneeStore } from '../signee/actions';
import { resetCompanyStore } from '../company/actions';
import { API_URLS } from '../constants';

export type LoginGoogleRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  token: string;
};

export type LoginRequest = {
  email: string;
  password: string;
};

export type RemindRequest = {
  email: string;
  locale: string;
};

export type ResetPasswordRequest = {
  email: string;
  password: string;
  code: string;
};

export type ValidateCodeRequest = {
  code: string;
};

export type RegisterRequest = {
  token?: string;
  password?: string;
  confirmPassword?: string;
  code: string;
};

export const loginWithGoogle =
  (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
    dispatch(loginGoogleStart());
    return axios
      .post(`${API_URLS.auth}/google`, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(setRole(response.data.role));
        dispatch(loginGoogleSuccess());
      })
      .catch((err) => {
        dispatch(loginGoogleFail(err?.response?.data?.message));
      });
  };

export const login = (inputs: LoginRequest) => (dispatch: Dispatch) => {
  dispatch(loginStart());
  return axios
    .post(`${API_URLS.auth}/login`, {
      ...inputs,
    })
    .then(async (response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(setRole(response.data.role));
      dispatch(loginSuccess());
    })
    .catch((err) => {
      dispatch(loginFail(err?.response?.data?.message));
    });
};

export const remind = (inputs: RemindRequest) => (dispatch: Dispatch) => {
  dispatch(remindStart());
  return axios
    .post(`${API_URLS.auth}/remind`, {
      ...inputs,
    })
    .then(async (response) => {
      dispatch(remindSuccess());
    })
    .catch((err) => {
      dispatch(remindFail(err?.response?.data?.message));
    });
};

export const resetPassword =
  (inputs: ResetPasswordRequest) => (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    return axios
      .patch(`${API_URLS.auth}/reset-password`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(setRole(response.data.role));
        dispatch(resetPasswordSuccess());
      })
      .catch((err) => {
        dispatch(resetPasswordFail(err?.response?.data?.message));
      });
  };

export const register = (inputs: RegisterRequest) => (dispatch: Dispatch) => {
  dispatch(registerStart());
  return axios
    .patch(`${API_URLS.auth}/register`, {
      ...inputs,
    })
    .then(async (response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(setRole(response.data.role));
      dispatch(registerSuccess());
    })
    .catch((err) => {
      dispatch(registerFail(err?.response?.data?.message));
    });
};

export const validateCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateCodeStart());
    return axios
      .post(`${API_URLS.auth}/validate-confirmation-code`, {
        ...inputs,
      })
      .then(async (response) => {
        dispatch(validateCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateCodeFail(err?.response?.data?.message));
      });
  };

export const validateRegistrationCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateRegistrationCodeStart());
    return axios
      .post(`${API_URLS.auth}/validate-registration-code`, {
        ...inputs,
      })
      .then(async (response) => {
        dispatch(validateRegistrationCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateRegistrationCodeFail(err?.response?.data?.message));
      });
  };

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(loginGoogleSuccess()) : dispatch(logout());
};

export const logoutUser = () => (dispatch: Dispatch) => {
  localStorage.removeItem('selectedCompany');
  dispatch(logout());
  dispatch(resetDocumentStore());
  dispatch(resetSigneeStore());
  dispatch(resetCompanyStore());
};
