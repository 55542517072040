import * as actionTypes from './actionTypes';
import { LanguageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Language } from '../../domain/Language';

export type LanguageStateType = {
  language: Language | null;
  languageLoading: boolean;
  languageError: HttpError;
  languages: Language[] | null;
  languagesLoading: boolean;
  languagesError: HttpError;
};

export type LanguageActionType = LanguageStateType & {
  type: LanguageActionTypes;
};

export const initialState: LanguageStateType = {
  language: null,
  languageLoading: false,
  languageError: null,
  languages: null,
  languagesLoading: false,
  languagesError: null,
};

const fetchLanguageStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languageLoading: true,
});

const fetchLanguageSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  language: action.language,
  languageLoading: false,
  languageError: null,
});

const fetchLanguageFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageError: action.languageError,
  languageLoading: false,
});

const fetchLanguagesStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languagesLoading: true,
});

const fetchLanguagesSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languages: action.languages,
  languagesLoading: false,
  languagesError: null,
});

const fetchLanguagesFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languagesError: action.languagesError,
  languagesLoading: false,
});

const reducer = (state = initialState, action: LanguageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LANGUAGE_START:
      return fetchLanguageStart(state);
    case actionTypes.FETCH_LANGUAGE_SUCCESS:
      return fetchLanguageSuccess(state, action);
    case actionTypes.FETCH_LANGUAGE_FAIL:
      return fetchLanguageFail(state, action);
    case actionTypes.FETCH_LANGUAGES_START:
      return fetchLanguagesStart(state);
    case actionTypes.FETCH_LANGUAGES_SUCCESS:
      return fetchLanguagesSuccess(state, action);
    case actionTypes.FETCH_LANGUAGES_FAIL:
      return fetchLanguagesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
