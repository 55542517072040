import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { TaskTemplate } from '../../domain/TaskTemplate';
import { TaskTemplateActionTypes } from './actionTypes';

export type TaskTemplateStateType = {
  taskTemplatesList: ListResults<TaskTemplate> | null;
  taskTemplatesListLoading: boolean;
  taskTemplatesListError: HttpError;
  taskTemplatesListUpdateNeeded: boolean;
  taskTemplateCreateLoading: boolean;
  taskTemplateCreateError: HttpError;
  taskTemplateCreateSuccess: boolean;
  createdTaskTemplate: TaskTemplate | null;
  taskTemplateUpdateLoading: boolean;
  taskTemplateUpdateError: HttpError;
  taskTemplateUpdateSuccess: boolean;
  taskTemplate: TaskTemplate | null;
  taskTemplateLoading: boolean;
  taskTemplateError: HttpError;
  taskTemplateDeleteLoading: boolean;
  taskTemplateDeleteError: HttpError;
  taskTemplateDeleteSuccess: boolean;
  taskTemplates: TaskTemplate[];
  taskTemplatesLoading: boolean;
  taskTemplatesError: HttpError;
};

export type TaskTemplateActionType = TaskTemplateStateType & {
  type: TaskTemplateActionTypes;
};

export const initialState: TaskTemplateStateType = {
  taskTemplatesList: null,
  taskTemplatesListLoading: true,
  taskTemplatesListError: null,
  taskTemplatesListUpdateNeeded: false,
  taskTemplateCreateLoading: false,
  taskTemplateCreateError: null,
  taskTemplateCreateSuccess: false,
  createdTaskTemplate: null,
  taskTemplateUpdateLoading: false,
  taskTemplateUpdateError: null,
  taskTemplateUpdateSuccess: false,
  taskTemplate: null,
  taskTemplateLoading: false,
  taskTemplateError: null,
  taskTemplateDeleteLoading: false,
  taskTemplateDeleteError: null,
  taskTemplateDeleteSuccess: false,
  taskTemplates: [],
  taskTemplatesLoading: false,
  taskTemplatesError: null,
};

const fetchTaskTemplatesStart = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplatesListLoading: true,
});

const fetchTaskTemplatesSuccess = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplatesList: action.taskTemplatesList,
  taskTemplatesListLoading: false,
  taskTemplatesListError: null,
  taskTemplatesListUpdateNeeded: false,
  taskTemplateCreateSuccess: false,
  taskTemplateUpdateSuccess: false,
  taskTemplateDeleteSuccess: false,
});

const fetchTaskTemplatesFail = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplatesListError: action.taskTemplatesListError,
  taskTemplatesListLoading: false,
});

const fetchAllTaskTemplatesStart = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplatesLoading: true,
});

const fetchAllTaskTemplatesSuccess = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplates: action.taskTemplates,
  taskTemplatesLoading: false,
  taskTemplatesError: null,
});

const fetchAllTaskTemplatesFail = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplatesError: action.taskTemplatesError,
  taskTemplatesLoading: false,
});

const fetchTaskTemplateStart = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateLoading: true,
  taskTemplateUpdateSuccess: false,
  taskTemplateCreateSuccess: false,
  taskTemplateCreateError: null,
  taskTemplateUpdateError: null,
});

const fetchTaskTemplateSuccess = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplate: action.taskTemplate,
  taskTemplateLoading: false,
  taskTemplateError: null,
});

const fetchTaskTemplateFail = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateError: action.taskTemplateError,
  taskTemplateLoading: false,
});

const createTaskTemplateStart = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateCreateLoading: true,
});

const createTaskTemplateSuccess = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateCreateLoading: false,
  taskTemplateCreateError: null,
  taskTemplateCreateSuccess: true,
  createdTaskTemplate: action.createdTaskTemplate,
});

const createTaskTemplateFail = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateCreateLoading: false,
  taskTemplateCreateError: action.taskTemplateCreateError,
});

const updateTaskTemplateStart = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateUpdateLoading: true,
});

const updateTaskTemplateSuccess = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateUpdateLoading: false,
  taskTemplateUpdateError: null,
  taskTemplateUpdateSuccess: true,
});

const updateTaskTemplateFail = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateUpdateLoading: false,
  taskTemplateUpdateError: action.taskTemplateUpdateError,
});

const deleteTaskTemplateStart = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateDeleteLoading: true,
});

const deleteTaskTemplateSuccess = (
  state: TaskTemplateStateType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateDeleteLoading: false,
  taskTemplateDeleteError: null,
  taskTemplateDeleteSuccess: true,
  taskTemplatesListUpdateNeeded: true,
});

const deleteTaskTemplateFail = (
  state: TaskTemplateStateType,
  action: TaskTemplateActionType,
): TaskTemplateStateType => ({
  ...state,
  taskTemplateDeleteLoading: false,
  taskTemplateDeleteError: action.taskTemplateDeleteError,
});

const resetTaskTemplateStore = (): TaskTemplateStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TaskTemplateActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TASK_TEMPLATES_START:
      return fetchTaskTemplatesStart(state);
    case actionTypes.FETCH_TASK_TEMPLATES_SUCCESS:
      return fetchTaskTemplatesSuccess(state, action);
    case actionTypes.FETCH_TASK_TEMPLATES_FAIL:
      return fetchTaskTemplatesFail(state, action);
    case actionTypes.FETCH_TASK_TEMPLATE_START:
      return fetchTaskTemplateStart(state);
    case actionTypes.FETCH_TASK_TEMPLATE_SUCCESS:
      return fetchTaskTemplateSuccess(state, action);
    case actionTypes.FETCH_TASK_TEMPLATE_FAIL:
      return fetchTaskTemplateFail(state, action);
    case actionTypes.CREATE_TASK_TEMPLATE_START:
      return createTaskTemplateStart(state);
    case actionTypes.CREATE_TASK_TEMPLATE_SUCCESS:
      return createTaskTemplateSuccess(state, action);
    case actionTypes.CREATE_TASK_TEMPLATE_FAIL:
      return createTaskTemplateFail(state, action);
    case actionTypes.UPDATE_TASK_TEMPLATE_START:
      return updateTaskTemplateStart(state);
    case actionTypes.UPDATE_TASK_TEMPLATE_SUCCESS:
      return updateTaskTemplateSuccess(state);
    case actionTypes.UPDATE_TASK_TEMPLATE_FAIL:
      return updateTaskTemplateFail(state, action);
    case actionTypes.DELETE_TASK_TEMPLATE_START:
      return deleteTaskTemplateStart(state);
    case actionTypes.DELETE_TASK_TEMPLATE_SUCCESS:
      return deleteTaskTemplateSuccess(state);
    case actionTypes.DELETE_TASK_TEMPLATE_FAIL:
      return deleteTaskTemplateFail(state, action);
    case actionTypes.FETCH_ALL_TASK_TEMPLATES_START:
      return fetchAllTaskTemplatesStart(state);
    case actionTypes.FETCH_ALL_TASK_TEMPLATES_SUCCESS:
      return fetchAllTaskTemplatesSuccess(state, action);
    case actionTypes.FETCH_ALL_TASK_TEMPLATES_FAIL:
      return fetchAllTaskTemplatesFail(state, action);
    case actionTypes.RESET_TASK_TEMPLATE_STORE:
      return resetTaskTemplateStore();
    default:
      return state;
  }
};

export default reducer;
