export const FETCH_SIGNEES_START = 'FETCH_SIGNEES_START';
export const FETCH_SIGNEES_SUCCESS = 'FETCH_SIGNEES_SUCCESS';
export const FETCH_SIGNEES_FAIL = 'FETCH_SIGNEES_FAIL';

export const FETCH_SIGNEE_START = 'FETCH_SIGNEE_START';
export const FETCH_SIGNEE_SUCCESS = 'FETCH_SIGNEE_SUCCESS';
export const FETCH_SIGNEE_FAIL = 'FETCH_SIGNEE_FAIL';

export const CREATE_SIGNEE_START = 'CREATE_SIGNEE_START';
export const CREATE_SIGNEE_SUCCESS = 'CREATE_SIGNEE_SUCCESS';
export const CREATE_SIGNEE_FAIL = 'CREATE_SIGNEE_FAIL';

export const UPDATE_SIGNEE_START = 'UPDATE_SIGNEE_START';
export const UPDATE_SIGNEE_SUCCESS = 'UPDATE_SIGNEE_SUCCESS';
export const UPDATE_SIGNEE_FAIL = 'UPDATE_SIGNEE_FAIL';

export const DELETE_SIGNEE_START = 'DELETE_SIGNEE_START';
export const DELETE_SIGNEE_SUCCESS = 'DELETE_SIGNEE_SUCCESS';
export const DELETE_SIGNEE_FAIL = 'DELETE_SIGNEE_FAIL';

export const TOGGLE_SIGNEE_STATUS_START = 'TOGGLE_SIGNEE_STATUS_START';
export const TOGGLE_SIGNEE_STATUS_SUCCESS = 'TOGGLE_SIGNEE_STATUS_SUCCESS';
export const TOGGLE_SIGNEE_STATUS_FAIL = 'TOGGLE_SIGNEE_STATUS_FAIL';

export const RESET_SIGNEE_STORE = 'RESET_SIGNEE_STORE';
export const RESET_CREATED_SIGNEE = 'RESET_CREATED_SIGNEE';

export type SigneeActionTypes =
  | typeof FETCH_SIGNEES_START
  | typeof FETCH_SIGNEES_SUCCESS
  | typeof FETCH_SIGNEES_FAIL
  | typeof FETCH_SIGNEE_START
  | typeof FETCH_SIGNEE_SUCCESS
  | typeof FETCH_SIGNEE_FAIL
  | typeof CREATE_SIGNEE_START
  | typeof CREATE_SIGNEE_SUCCESS
  | typeof CREATE_SIGNEE_FAIL
  | typeof UPDATE_SIGNEE_START
  | typeof UPDATE_SIGNEE_SUCCESS
  | typeof UPDATE_SIGNEE_FAIL
  | typeof RESET_SIGNEE_STORE
  | typeof RESET_CREATED_SIGNEE
  | typeof DELETE_SIGNEE_START
  | typeof DELETE_SIGNEE_SUCCESS
  | typeof TOGGLE_SIGNEE_STATUS_START
  | typeof TOGGLE_SIGNEE_STATUS_SUCCESS
  | typeof TOGGLE_SIGNEE_STATUS_FAIL
  | typeof DELETE_SIGNEE_FAIL;
