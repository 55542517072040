import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Signee, SigneesList } from '../../domain/Signee';

export const fetchSigneesStart = () => ({
  type: actionTypes.FETCH_SIGNEES_START,
});

export const fetchSigneesSuccess = (signeesList: SigneesList) => ({
  type: actionTypes.FETCH_SIGNEES_SUCCESS,
  signeesList,
});

export const fetchSigneesFail = (signeesError: HttpError) => ({
  type: actionTypes.FETCH_SIGNEES_FAIL,
  signeesError,
});

export const fetchSigneeStart = () => ({
  type: actionTypes.FETCH_SIGNEE_START,
});

export const fetchSigneeSuccess = (signee: Signee) => ({
  type: actionTypes.FETCH_SIGNEE_SUCCESS,
  signee,
});

export const fetchSigneeFail = (signeeError: HttpError) => ({
  type: actionTypes.FETCH_SIGNEE_FAIL,
  signeeError,
});

export const createSigneeStart = () => ({
  type: actionTypes.CREATE_SIGNEE_START,
});

export const createSigneeSuccess = (createdSignee: Signee) => ({
  type: actionTypes.CREATE_SIGNEE_SUCCESS,
  createdSignee,
});

export const createSigneeFail = (signeeCreateError: HttpError) => ({
  type: actionTypes.CREATE_SIGNEE_FAIL,
  signeeCreateError,
});

export const updateSigneeStart = () => ({
  type: actionTypes.UPDATE_SIGNEE_START,
});

export const updateSigneeSuccess = () => ({
  type: actionTypes.UPDATE_SIGNEE_SUCCESS,
});

export const updateSigneeFail = (signeeUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_SIGNEE_FAIL,
  signeeUpdateError,
});

export const deleteSigneeStart = () => ({
  type: actionTypes.DELETE_SIGNEE_START,
});

export const deleteSigneeSuccess = () => ({
  type: actionTypes.DELETE_SIGNEE_SUCCESS,
});

export const deleteSigneeFail = (signeeDeleteError: HttpError) => ({
  type: actionTypes.DELETE_SIGNEE_FAIL,
  signeeDeleteError,
});

export const toggleSigneeStatusStart = () => ({
  type: actionTypes.TOGGLE_SIGNEE_STATUS_START,
});

export const toggleSigneeStatusSuccess = () => ({
  type: actionTypes.TOGGLE_SIGNEE_STATUS_SUCCESS,
});

export const toggleSigneeStatusFail = (toggleSigneeStatusError: HttpError) => ({
  type: actionTypes.TOGGLE_SIGNEE_STATUS_FAIL,
  toggleSigneeStatusError,
});

export const resetCreatedSignee = () => ({
  type: actionTypes.RESET_CREATED_SIGNEE,
});

export const resetSigneeStore = () => ({
  type: actionTypes.RESET_SIGNEE_STORE,
});
