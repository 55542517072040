export const FETCH_MY_TASKS_START = 'FETCH_MY_TASKS_START';
export const FETCH_MY_TASKS_SUCCESS = 'FETCH_MY_TASKS_SUCCESS';
export const FETCH_MY_TASKS_FAIL = 'FETCH_MY_TASKS_FAIL';

export const FETCH_MY_TASK_START = 'FETCH_MY_TASK_START';
export const FETCH_MY_TASK_SUCCESS = 'FETCH_MY_TASK_SUCCESS';
export const FETCH_MY_TASK_FAIL = 'FETCH_MY_TASK_FAIL';

export const ACKNOWLEDGE_TASK_DOCUMENT_START =
  'ACKNOWLEDGE_TASK_DOCUMENT_START';
export const ACKNOWLEDGE_TASK_DOCUMENT_SUCCESS =
  'ACKNOWLEDGE_TASK_DOCUMENT_SUCCESS';
export const ACKNOWLEDGE_TASK_DOCUMENT_FAIL = 'ACKNOWLEDGE_TASK_DOCUMENT_FAIL';

export const ACKNOWLEDGE_TASK_START = 'ACKNOWLEDGE_TASK_START';
export const ACKNOWLEDGE_TASK_SUCCESS = 'ACKNOWLEDGE_TASK_SUCCESS';
export const ACKNOWLEDGE_TASK_FAIL = 'ACKNOWLEDGE_TASK_FAIL';

export const RESEND_CODE_START = 'RESEND_CODE_START';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_FAIL = 'RESEND_CODE_FAIL';

export const SIGN_TASK_START = 'SIGN_TASK_START';
export const SIGN_TASK_SUCCESS = 'SIGN_TASK_SUCCESS';
export const SIGN_TASK_FAIL = 'SIGN_TASK_FAIL';

export const EXPORT_DATA_START = 'EXPORT_DATA_START';
export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const EXPORT_DATA_FAIL = 'EXPORT_DATA_FAIL';

export const RESET_RESEND_CODE_STORE = 'RESET_RESEND_CODE_STORE';
export const RESET_SIGN_TASK_STORE = 'RESET_SIGN_TASK_STORE';
export const RESET_EXPORT_STORE = 'RESET_EXPORT_STORE';
export const RESET_TASK_STORE = 'RESET_TASK_STORE';

export type TaskActionTypes =
  | typeof FETCH_MY_TASKS_START
  | typeof FETCH_MY_TASKS_SUCCESS
  | typeof FETCH_MY_TASKS_FAIL
  | typeof FETCH_MY_TASK_START
  | typeof FETCH_MY_TASK_SUCCESS
  | typeof FETCH_MY_TASK_FAIL
  | typeof ACKNOWLEDGE_TASK_DOCUMENT_START
  | typeof ACKNOWLEDGE_TASK_DOCUMENT_SUCCESS
  | typeof ACKNOWLEDGE_TASK_DOCUMENT_FAIL
  | typeof ACKNOWLEDGE_TASK_START
  | typeof ACKNOWLEDGE_TASK_SUCCESS
  | typeof ACKNOWLEDGE_TASK_FAIL
  | typeof RESEND_CODE_START
  | typeof RESEND_CODE_SUCCESS
  | typeof RESEND_CODE_FAIL
  | typeof SIGN_TASK_START
  | typeof SIGN_TASK_SUCCESS
  | typeof SIGN_TASK_FAIL
  | typeof EXPORT_DATA_START
  | typeof EXPORT_DATA_SUCCESS
  | typeof EXPORT_DATA_FAIL
  | typeof RESET_EXPORT_STORE
  | typeof RESET_RESEND_CODE_STORE
  | typeof RESET_SIGN_TASK_STORE
  | typeof RESET_TASK_STORE;
