export const API_URLS = {
  auth: '/auth',
  translation: '/translations',
  signee: '/signees',
  document: '/documents',
  language: '/languages',
  company: '/companies',
  user: '/users',
  sign: '/signatures',
  group: '/groups',
  jobTitle: 'job-titles',
  mainTask: 'main-tasks',
  tasks: 'tasks',
  taskTemplates: '/task-templates',
};
